import xxhash from "xxhash-wasm";

import { $StringVec, GitObject, MultiObject } from "./codec";

const buildMultiObject = async (
  objects: Map<string, GitObject>
): Promise<MultiObject> => {
  const gitHashes = new Array(...objects.keys());

  const { h64Raw } = await xxhash();
  const hash = h64Raw($StringVec.encode(gitHashes)).toString();

  const multiObject: MultiObject = { hash, git_hashes: gitHashes, objects };

  return multiObject;
};

export default buildMultiObject;
