import { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import create from "zustand";
import { persist } from "zustand/middleware";

type AccountState = {
  accounts: InjectedAccountWithMeta[];
  selectedAccount: InjectedAccountWithMeta | null;
  setAccounts: (accounts: InjectedAccountWithMeta[]) => void;
  setSelectedAccount: (account: InjectedAccountWithMeta | null) => void;
  crustSignatures: {
    [address: string]: string;
  };
  setNewCrustSignature: ({
    address,
    signature,
  }: {
    address: string;
    signature: string;
  }) => void;
};

const useAccount = create<AccountState>()(
  persist(
    (set) => ({
      accounts: [],
      selectedAccount: null,
      crustSignatures: {},
      setAccounts: (accounts: InjectedAccountWithMeta[]) =>
        set(() => ({ accounts })),
      setSelectedAccount: (account: InjectedAccountWithMeta | null) =>
        set(() => ({ selectedAccount: account })),
      setNewCrustSignature: ({
        address,
        signature,
      }: {
        address: string;
        signature: string;
      }) =>
        set((state) => ({
          crustSignatures: {
            ...state.crustSignatures,
            [address]: signature,
          },
        })),
    }),
    {
      name: "account",
    }
  )
);

export default useAccount;
