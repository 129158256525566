import { ApiPromise, WsProvider } from "@polkadot/api";
import { ApiOptions } from "@polkadot/api/types";
import create from "zustand";

import licenses from "../utils/licenses";

enum Host {
  // REMOTE = "wss://tinker.invarch.network",
  REMOTE = "wss://invarch-tinkernet.api.onfinality.io/public-ws",
  LOCAL = "ws://127.0.0.1:9944",
}

type RPCState = {
  host: Host;
  setHost: (host: Host) => void;
  createApi: (options?: ApiOptions) => Promise<ApiPromise>;
  error: unknown | null;
};

const useRPC = create<RPCState>()((set, get) => ({
  host: Host.REMOTE,
  setHost: (host: Host) => set(() => ({ host })),
  error: null,
  createApi: async () => {
    const { host } = get();

    const wsProvider = new WsProvider(host);

    try {
      set(() => ({ error: null }));

      const api = await ApiPromise.create({
        provider: wsProvider,
        types: {
          Licenses: {
            _enum: licenses,
          },
          OneOrPercent: {
            _enum: {
              One: null,
              Percent: "Percent",
            },
          },
          AnyId: {
            _enum: {
              IpfId: "u64",
              RmrkNft: "(u32, u32)",
              RmrkCollection: "u32",
              IpsId: "u32",
            },
          },
        },
      });

      return api;
    } catch (e) {
      set(() => ({ error: e }));

      throw new Error("Unable to connect to RPC");
    }
  },
}));

export { Host };

export default useRPC;
