import create from "zustand";

enum ModalName {
  SELECT_ACCOUNT,
  EDIT_IDENTITY,
  NEW_REPO,
}

type Metadata =
  | {
      user: {
        display?: string;
        legal?: string;
        web?: string;
        riot?: string;
        email?: string;
        twitter?: string;
        image?: string;
      };
      fork?: never;
    }
  | {
      fork: {
        id: string;
        name: string;
        description: string;
      };
      user?: never;
    };

type ModalState = {
  openModal: ModalName | null;
  setOpenModal: ({
    name,
    metadata,
  }: {
    name: ModalName | null;
    metadata?: Metadata;
  }) => void;
  metadata?: Metadata;
};

const useModal = create<ModalState>()((set) => ({
  openModal: null,
  setOpenModal: ({
    name,
    metadata,
  }: {
    name: ModalName | null;
    metadata?: Metadata;
  }) => set(() => ({ openModal: name, metadata })),
  metadata: undefined,
}));

export { ModalName };

export default useModal;
