import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { hexToU8a } from "@polkadot/util";
import { useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import shallow from "zustand/shallow";

import LoadingSpinner from "../../../components/LoadingSpinner";
import useAccount from "../../../stores/account";
import useRPC from "../../../stores/rpc";
import Multisig from "./Multisig";
import NewMultisig from "./NewMultisig";

const MultisigList = ({
  multisigs,
  id,
}: {
  multisigs: {
    hash: string;
    originalCaller: string;
    call: string;
    voters: number;
  }[];
  id: string;
}) => {
  return (
    <ul className="flex flex-col gap-4">
      {multisigs.map((interaction) => (
        <li
          key={interaction.hash}
          className="rounded-md border bg-neutral-900 shadow"
        >
          <Link
            to={`/repo/${id}/multisig/${interaction.hash}`}
            className="block"
          >
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="flex min-w-0 flex-1 items-center">
                <div className="flex flex-col gap-2">
                  <p className="truncate text-sm font-medium text-neutral-100">
                    <span className="font-bold">Call:</span> {interaction.call}
                  </p>
                  <p className="truncate text-sm font-medium text-neutral-100">
                    <span className="font-bold">Original Caller:</span>{" "}
                    {interaction.originalCaller}
                  </p>
                  <p className="truncate text-sm font-medium text-neutral-100">
                    <span className="font-bold">Voters:</span>{" "}
                    {interaction.voters}
                  </p>
                  <p className="truncate text-sm font-medium text-neutral-100">
                    <span className="font-bold">Hash:</span> {interaction.hash}
                  </p>
                </div>
              </div>
              <div>
                <ChevronRightIcon
                  className="h-5 w-5 text-neutral-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
};

const Multisigs = () => {
  const { id } = useParams();
  const { createApi } = useRPC();
  const [multisigs, setMultisigs] = useState<
    | { hash: string; originalCaller: string; call: string; voters: number }[]
    | null
  >(null);
  const navigate = useNavigate();
  const { selectedAccount } = useAccount(
    (state) => ({ selectedAccount: state.selectedAccount }),
    shallow
  );
  const [isLoading, setIsLoading] = useState(true);

  if (!id) {
    navigate("/404", { replace: true });

    throw new Error("ID_NOT_FOUND");
  }

  useEffect(() => {
    (async () => {
      if (multisigs) return;

      setIsLoading(true);

      const api = await createApi();

      const pendingMultisigs = (await api.query.inv4.multisig.entries(id)).map(
        ([key, multisig]) => {
          const m = multisig.toPrimitive() as {
            originalCaller: string;
            callMetadata: string;
            signers: [string, number][];
            metadata: string | null;
          };

          let metadata = null;

          if (m.metadata) {
            try {
              metadata = JSON.parse(m.metadata) as {
                protocol: string;
                type: string;
              };
            } catch {
              metadata = null;
            }
          }

          const { section, method } = api.registry.findMetaCall(
            hexToU8a(m.callMetadata)
          );

          return {
            hash: key.args[1].toHex(),
            originalCaller: m.originalCaller,
            call:
              metadata?.protocol === "inv4-git"
                ? `Git ${metadata.type}`
                : `${section}.${method}`,
            voters: m.signers.length,
          };
        }
      );

      setMultisigs(pendingMultisigs);

      setIsLoading(false);
    })();
  }, [multisigs]);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {selectedAccount ? (
                    <div className="flex items-center justify-between">
                      <div>{/* input form will be here */}</div>

                      <div>
                        <Link
                          to="new"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-amber-400 py-2 px-4 text-sm font-bold text-neutral-900 shadow-sm transition-colors hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
                        >
                          Create new interaction
                        </Link>
                      </div>
                    </div>
                  ) : null}

                  {!multisigs ? null : multisigs.length > 0 ? (
                    <MultisigList multisigs={multisigs} id={id} />
                  ) : (
                    <span>No interactions yet</span>
                  )}
                </>
              )}
            </>
          }
        />

        <Route path=":hash/*" element={<Multisig />} />

        <Route path="new" element={<NewMultisig />} />
      </Routes>
    </>
  );
};

export default Multisigs;
