import { ClipboardIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import shallow from "zustand/shallow";

import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import useRepo from "../../stores/repo";

const Setup = () => {
  const [, copyText] = useCopyToClipboard();
  const { id } = useParams();
  const { repoData } = useRepo(
    (state) => ({ repoData: state.repoData, repoDataId: state.repoDataId }),
    shallow
  );
  const navigate = useNavigate();

  const inv4GitRef = useRef<HTMLDivElement>(null);
  const gitInitRef = useRef<HTMLDivElement>(null);
  const gitRemoteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (repoData) {
      navigate(`/repo/${id}/tree`);
    }
  }, [repoData]);

  return (
    <>
      <div>
        <h1 className="text-xl font-medium leading-6 text-white">
          Quick setup
        </h1>
        <p className="mt-1 text-sm text-neutral-400">
          We trust you, you know what you&apos;re doing, you&apos;ll be fine.
        </p>
      </div>

      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <div>
            <h3 className="text-lg font-bold">
              Do you have <span className="font-mono">rust</span> and{" "}
              <span className="font-mono">cargo</span> installed?
            </h3>
            <p className="mt-1 text-sm text-neutral-400">
              If not, here it goes a link for you to follow
            </p>
          </div>

          <div>
            <p>
              <a
                href="https://doc.rust-lang.org/cargo/getting-started/installation.html"
                target="_blank"
                rel="noreferrer"
                className="text-amber-400 hover:text-amber-600"
              >
                Installation guide
              </a>
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div>
            <h3 className="text-lg font-bold">
              Do you have <span className="font-mono">inv4-git</span> installed?
            </h3>
            <p className="mt-1 text-sm text-neutral-400">
              If not, let&apos;s set it up right now!
            </p>
          </div>

          <div className="relative rounded-md bg-neutral-600 p-8">
            <button
              className="absolute top-4 right-4 cursor-pointer rounded-md bg-neutral-800 p-2 text-neutral-200 transition-colors hover:bg-neutral-400"
              onClick={() => {
                if (inv4GitRef.current?.innerText) {
                  copyText(inv4GitRef.current?.innerText);

                  toast.success("Copied to clipboard!");
                }
              }}
            >
              <ClipboardIcon className="h-6 w-6" />
            </button>

            <div ref={inv4GitRef} className="font-mono">
              cargo install --git https://github.com/InvArch/INV4-Git --features
              crust
            </div>
          </div>

          <p>
            Additional information can be found{" "}
            <a
              href="https://github.com/InvArch/INV4-Git#readme"
              target="_blank"
              rel="noreferrer"
              className="text-amber-400 hover:text-amber-600"
            >
              here
            </a>
          </p>
        </div>

        <div className="flex flex-col gap-4">
          <div>
            <h3 className="text-lg font-bold">
              Now you just need to initialize the repository
            </h3>
            <p className="mt-1 text-sm text-neutral-400">
              It&apos;s very easy to do so, you can simply copy and paste this
              snippet
            </p>
          </div>

          <div className="relative rounded-md bg-neutral-600 p-8">
            <button
              className="absolute top-4 right-4 cursor-pointer rounded-md bg-neutral-800 p-2 text-neutral-200 transition-colors hover:bg-neutral-400"
              onClick={() => {
                if (gitInitRef.current?.innerText) {
                  copyText(gitInitRef.current?.innerText);

                  toast.success("Copied to clipboard!");
                }
              }}
            >
              <ClipboardIcon className="h-6 w-6" />
            </button>

            <div ref={gitInitRef} className="font-mono">
              git init
              <br />
              git add .
              <br />
              git commit -m &quot;first commit&quot;
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div>
            <h3 className="text-lg font-bold">
              And then you just need to add the remote
            </h3>
            <p className="mt-1 text-sm text-neutral-400">
              It&apos;s very easy to do so, you can simply copy and paste this
              snippet
            </p>
          </div>

          <div className="relative rounded-md bg-neutral-600 p-8">
            <button
              className="absolute top-4 right-4 cursor-pointer rounded-md bg-neutral-800 p-2 text-neutral-200 transition-colors hover:bg-neutral-400"
              onClick={() => {
                if (gitRemoteRef.current?.innerText) {
                  copyText(gitRemoteRef.current?.innerText);

                  toast.success("Copied to clipboard!");
                }
              }}
            >
              <ClipboardIcon className="h-6 w-6" />
            </button>

            <div ref={gitRemoteRef} className="font-mono">
              git remote add origin inv4://{id}
              <br />
              git push -u origin HEAD
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div>
            <h3 className="text-lg font-bold">
              After that, you can refresh this page! 🎉🎉
            </h3>
            <p className="mt-1 text-sm text-neutral-400">
              After we have detected the repository, you will be redirected to
              the tree view
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setup;
