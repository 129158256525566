import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import bannerImage from "../assets/gitarch-banner-home.svg";
import logoFull from "../assets/logo-full.svg";
import Head from "../components/Head";
import useConnect from "../hooks/useConnect";
import useAccount from "../stores/account";
import useModal, { ModalName } from "../stores/modals";

const Home = () => {
  const { register, handleSubmit } = useForm<{ id: string }>();
  const { handleConnect } = useConnect();
  const selectedAccount = useAccount((state) => state.selectedAccount);
  const { setOpenModal } = useModal(
    (state) => ({ setOpenModal: state.setOpenModal }),
    shallow
  );
  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => navigate(`/repo/${data.id}`));

  return (
    <>
      <Head
        title="Unstoppable Code"
        description="Your code is meant to be unstoppable."
      />

      <div className="relative min-h-screen bg-neutral-900 text-white">
        <header className="flex items-center justify-center p-8">
          <Link to="/">
            <img className="h-12 w-auto" src={logoFull} alt="GitArch Logo" />
          </Link>
        </header>
        <main className="mx-auto flex max-w-4xl flex-col items-center gap-8 p-8 sm:flex-row ">
          <div className="flex w-full flex-col gap-4 sm:w-1/2">
            <h1 className="text-4xl font-bold">
              Let&apos;s build from here, magically
            </h1>

            <span>
              The complete developer platform to build, scale, and deliver
              secure software
            </span>

            <>
              <form onSubmit={onSubmit} className="flex flex-col gap-4">
                <input
                  type="text"
                  {...register("id", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  className="block w-full rounded-md border border-neutral-300 bg-neutral-900 p-4 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                  placeholder="Enter your repo ID"
                />

                <button className="inline-flex w-full justify-center rounded-md border border-transparent bg-amber-400 py-2 px-4 text-sm font-bold text-neutral-900 shadow-sm transition-colors hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2">
                  Go to repo
                </button>
              </form>

              {selectedAccount ? (
                <span>
                  Don&apos;t have a repo?{" "}
                  <button
                    onClick={() => {
                      setOpenModal({ name: ModalName.NEW_REPO });
                    }}
                    className="text-amber-400 transition-colors hover:text-amber-200"
                  >
                    Create one
                  </button>
                </span>
              ) : (
                <span>
                  <button
                    onClick={handleConnect}
                    className="text-amber-400 transition-colors hover:text-amber-200"
                  >
                    Connect your wallet
                  </button>{" "}
                  to create a repo
                </span>
              )}
            </>
          </div>

          <div className="relative hidden h-96 w-1/2 sm:block">
            <img
              src={bannerImage}
              alt="background"
              className="absolute right-0 h-full w-full"
            />
          </div>
        </main>
        <footer className="absolute bottom-0 flex w-full justify-center border-t border-neutral-600 p-8">
          &copy; {new Date().getFullYear()} GitArch. All rights reserved.
        </footer>
      </div>
    </>
  );
};

export default Home;
