import { Tab } from "@headlessui/react";
import { UserIcon } from "@heroicons/react/24/outline";
import { AnyJson } from "@polkadot/types/types";
import git, { CommitObject } from "isomorphic-git";
import { useEffect, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import YAML from "yaml";

import useRPC from "../../../../stores/rpc";
import classNames from "../../../../utils/classNames";
import {
  $MultiObject,
  $RepoData,
  GitObject,
  MultiObject,
} from "../../../../utils/codec";
import { decompressData } from "../../../../utils/compression";
import fs from "../../../../utils/fs";
import { getCommitsInMultiobject } from "../../../../utils/getCommits";
import { getGitDiff } from "../../../../utils/getGitDiff";
import { getIpfsData } from "../../../../utils/ipf";
import { timeAgo } from "../../../../utils/timeAgo";

enum DynamicCodeBlocksType {
  DEFAULT = "DEFAULT",
  GENERIC = "GENERIC",
  PUSH = "PUSH",
  MERGE = "MERGE",
}

const DynamicContent = ({
  content,
}: {
  content: {
    type: DynamicCodeBlocksType;
    data: { method: string; section: string; args: AnyJson };
  };
}) => {
  const [changes, setChanges] = useState<
    {
      path: string;
      type: string;
      originalCode?: string;
      newCode: string;
    }[]
  >([]);
  const [commits, setCommits] = useState<
    { hash: string; commit: CommitObject }[]
  >([]);
  const { id } = useParams<{ id: string }>();

  const { createApi } = useRPC();

  const writeObjectToDB = async (object: GitObject) => {
    const type = () => {
      switch (object.metadata._tag) {
        case "Commit":
          return "commit";
        case "Tag":
          return "tag";
        case "Tree":
          return "tree";
        case "Blob":
          return "blob";
      }
    };

    await git.writeObject({
      fs,
      dir: `/${id}`,
      format: "content",
      oid: object.git_hash,
      object: object.data,
      type: type(),
    });
  };

  const processMultiObject = async (multiObject: MultiObject) => {
    for (const object of multiObject.objects) {
      await writeObjectToDB(object[1]);
    }
  };

  const processGitOperation = async (call: {
    method: string;
    section: string;
    args: AnyJson;
  }) => {
    const api = await createApi();

    if (call.section === "utility" && call.method === "batchAll") {
      const args = call.args as {
        calls: {
          callIndex: string;
          args: { ips_id: number; assets: [{ ipfId: number }] };
        }[];
      };

      const appendCallData = args.calls
        .find(({ callIndex }) => {
          return callIndex == "0x4701";
        })
        ?.args.assets.map(({ ipfId }) => ipfId);

      if (!appendCallData) return;

      let repoData;
      let multiObject;

      for (const id of appendCallData) {
        const ipf = (await api.query.ipf.ipfStorage(id)).toPrimitive() as {
          metadata: string;
          data: string;
        };

        const fetchedData = await getIpfsData(ipf.data);

        const finalData = await decompressData(fetchedData);

        ipf.metadata == "RepoData"
          ? (repoData = $RepoData.decode(finalData))
          : (multiObject = $MultiObject.decode(finalData));
      }

      if (!repoData || !multiObject) return;

      await processMultiObject(multiObject);

      const newHead = Array.from(repoData.refs, ([ref, hash]) => ({
        ref,
        hash,
      })).find(
        async ({ ref, hash }) =>
          (await git.resolveRef({
            fs,
            dir: `/${id}`,
            ref,
          })) != hash.toString()
      );

      if (!newHead) return;

      const changes = await getGitDiff({
        id,
        oldHash: newHead.ref,
        newHash: newHead.hash,
      });

      setChanges(changes);

      const commits = await getCommitsInMultiobject(
        id,
        newHead.hash,
        multiObject,
        []
      );

      setCommits(commits);
    }
  };

  useEffect(() => {
    (async () => {
      switch (content.type) {
        case DynamicCodeBlocksType.PUSH || DynamicCodeBlocksType.MERGE:
          processGitOperation(content.data);
      }
    })();
  }, [content]);

  console.log({ content, changes, commits });

  return (
    <>
      {content.type === DynamicCodeBlocksType.DEFAULT ||
      content.type === DynamicCodeBlocksType.GENERIC ? (
        <div className="flex flex-col gap-8">
          <SyntaxHighlighter language="yaml" style={oneDark}>
            {YAML.stringify(content.data)}
          </SyntaxHighlighter>
        </div>
      ) : null}

      {(commits.length > 0 &&
        changes.length > 0 &&
        content.type === DynamicCodeBlocksType.PUSH) ||
      content.type === DynamicCodeBlocksType.MERGE ? (
        <Tab.Group>
          <Tab.List className="flex space-x-2">
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? "bg-neutral-100 text-neutral-700"
                    : "text-neutral-500 hover:text-neutral-100",
                  "cursor-pointer rounded-md px-3 py-2 text-sm font-medium"
                )
              }
            >
              Commits
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? "bg-neutral-100 text-neutral-700"
                    : "text-neutral-500 hover:text-neutral-100",
                  "cursor-pointer rounded-md px-3 py-2 text-sm font-medium"
                )
              }
            >
              Code
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel key={0}>
              <div>
                <ul className="divide-y divide-gray-500">
                  {commits.map((commit) => (
                    <li key={commit.hash} className="py-4">
                      <div className="flex space-x-3">
                        <UserIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                        <div className="flex-1 space-y-1">
                          <div className="flex items-center justify-between">
                            <div className="flex">
                              <h3 className="text-sm font-medium">
                                {commit.commit.author.name}
                              </h3>

                              <p className="text-sm text-gray-500">
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {commit.commit.message}
                              </p>
                            </div>
                            <p className="text-sm text-gray-500">
                              {timeAgo(commit.commit.author.timestamp)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Tab.Panel>
            <Tab.Panel key={1}>
              <div className="flex flex-col gap-8">
                {changes.map(({ path, originalCode, newCode }) => (
                  <div className="rounded-md" key={path}>
                    <ReactDiffViewer
                      oldValue={originalCode || ""}
                      newValue={newCode}
                      splitView={false}
                      useDarkTheme
                      compareMethod={DiffMethod.WORDS_WITH_SPACE}
                      leftTitle={path}
                    />
                  </div>
                ))}
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      ) : null}
    </>
  );
};

export { DynamicCodeBlocksType };

export default DynamicContent;
