import { ApiPromise } from "@polkadot/api";
import { CID } from "multiformats/cid";
import { decode } from "multiformats/hashes/digest";

const getIpfsData = async (hash: string): Promise<Uint8Array> => {
  const digest = decode(
    Uint8Array.from(Buffer.from(`1220${hash.replace("0x", "")}`, "hex"))
  );

  const cid = CID.create(1, 112, digest);

  const res = await fetch(`https://ipfs.io/ipfs/${cid}`);

  const arrayBuffer = await res.arrayBuffer();

  return new Uint8Array(arrayBuffer);
};

const getIpfList = async ({
  api,
  data,
}: {
  api: ApiPromise;
  data: number[];
}) => {
  const ipfList = [];

  for (const id of data) {
    const query = await api.query.ipf.ipfStorage(id);

    const { metadata, data } = query.toHuman() as {
      metadata: string;
      data: string;
    };

    ipfList.push({ metadata, data, id });
  }

  return ipfList;
};

export { getIpfList, getIpfsData };
