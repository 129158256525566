import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import git, { CommitObject, WalkerEntry } from "isomorphic-git";
import { filetypemime } from "magic-bytes.js";
import mimes from "mime/lite.js";
import path from "path";
import { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import { Prism } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import shallow from "zustand/shallow";

import LoadingSpinner from "../../../components/LoadingSpinner";
import useRepo from "../../../stores/repo";
import dataToUrl from "../../../utils/dataToUrl";
import fs from "../../../utils/fs";
import languageList from "../../../utils/langs.json";
import { timeAgo } from "../../../utils/timeAgo";
import FileTree from "./FileTree";

const Files = () => {
  const { repoPath, selectedBranch, previousPath } = useRepo(
    (state) => ({
      repoPath: state.repoPath,
      setSelectedBranch: state.setSelectedBranch,
      selectedBranch: state.selectedBranch,
      previousPath: state.previousPath,
    }),
    shallow
  );
  const [files, setFiles] = useState<
    {
      entry: WalkerEntry;
      filepath: string;
      lastCommit: CommitObject;
      fileType: "DIRECTORY" | "FILE";
    }[]
  >([]);
  const [fileType, setFileType] = useState<
    | {
        type: "FOLDER";
        markdown: string | null;
        lastCommit: CommitObject;
      }
    | {
        type: "FILE";
        extension: string;
        mime: string | null;
        file: Uint8Array | string;
        lastCommit: CommitObject;
      }
  >();
  const [images, setImages] = useState<Map<string, string>>(new Map());
  const [imagesLen, setImagesLen] = useState<number>(0);

  const { id } = useParams<{ id: string }>();

  const handleRepoPathChange = useCallback(async () => {
    if (!selectedBranch) return;

    await git.checkout({
      fs,
      dir: `/${id}`,
      ref: selectedBranch,
    });

    const extension = path.extname(repoPath);

    const ref = await git.expandRef({ fs, dir: `/${id}`, ref: selectedBranch });

    const cache = {};

    let current = null as unknown as {
      entry: WalkerEntry;
      lastCommit: CommitObject;
    };

    const files = await git.walk({
      fs,
      dir: `/${id}`,
      trees: [git.TREE({ ref })],
      map: async function (filepath, [entry]) {
        if (!filepath || !entry) return;

        let newFilePath = filepath;

        newFilePath = newFilePath.replace(repoPath, "");

        if (newFilePath.charAt(0) === "/") {
          newFilePath = newFilePath.substring(1);
        }

        if (filepath === repoPath) {
          current = {
            entry,
            lastCommit: (
              await git.log({
                fs,
                dir: `/${id}`,
                depth: 1,
                ref,
                filepath,
                cache,
              })
            )[0].commit,
          };
        }

        if (
          filepath.startsWith(repoPath) &&
          filepath != repoPath &&
          filepath != "." &&
          !newFilePath.replace(repoPath, "").includes("/")
        ) {
          const commits = await git.log({
            fs,
            dir: `/${id}`,
            depth: 1,
            ref,
            filepath,
            cache,
          });

          const lastCommit = commits[0].commit;

          const fileType =
            (await entry.mode()).toString(8) === "40000" ? "DIRECTORY" : "FILE";

          return { entry, filepath, lastCommit, fileType };
        }
      },
    });

    const readme = files.find(
      ({ filepath }: { filepath: string }) =>
        path.basename(filepath).toLowerCase() === "readme.md"
    );

    let markdown: string | null = null;

    if (readme) {
      markdown = String.fromCharCode(...(await readme.entry.content()));

      ReactMarkdown({
        children: markdown,
        rehypePlugins: [rehypeRaw],
        remarkPlugins: [remarkGfm],
        transformImageUri: (src) => {
          const mime = mimes.getType(path.extname(src));

          if (!mime) return src;

          if (src.startsWith("http")) {
            if (!images.get(src)) {
              const i = images;
              i.set(
                src,
                src.startsWith("https://github.com/")
                  ? src
                      .replace("//github.com/", "//raw.githubusercontent.com/")
                      .replace("/blob/", "/")
                  : src
              );
              setImages(i);
              setImagesLen(imagesLen + 1);
            }
          } else {
            fs.readFile("/" + src, {}, (e, data) => {
              if (!images.get(src)) {
                const i = images;
                i.set(src, dataToUrl(data, mime));
                setImages(i);
                setImagesLen(imagesLen + 1);
              }
            });
          }

          return src;
        },
      });
    }

    if (current) {
      if ((await current.entry.mode()).toString(8) === "40000") {
        setFileType({
          type: "FOLDER",
          markdown,
          lastCommit: current.lastCommit,
        });
      } else {
        const content = await current.entry.content();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const mime = filetypemime(content.slice(0, 100))[0];

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let file: Uint8Array | string = Buffer.from(content);

        if (mime) file = dataToUrl(file, mime);

        setFileType({
          type: "FILE",
          extension,
          file,
          mime,
          lastCommit: current.lastCommit,
        });
      }
    } else {
      setFileType({
        type: "FOLDER",
        markdown,
        lastCommit: (
          await git.log({
            fs,
            dir: `/${id}`,
            depth: 1,
            ref,
            filepath: repoPath,
            cache,
          })
        )[0].commit,
      });
    }

    setFiles(files);
  }, [repoPath, imagesLen, selectedBranch, id]);

  useEffect(() => {
    handleRepoPathChange();
  }, [handleRepoPathChange]);

  return (
    <div className="flex flex-col gap-8">
      {fileType ? (
        <>
          <div className="flex h-10 items-center justify-between gap-8">
            {fileType.type === "FILE" && previousPath !== null ? (
              <Link
                to={previousPath}
                className="flex items-center gap-2 rounded-md border p-4 text-sm transition-colors hover:border-neutral-400 hover:text-neutral-400"
              >
                <ArrowLeftIcon className="h-5 w-5" />
                <span>Back</span>
              </Link>
            ) : (
              <div />
            )}
            <div>
              <span className="truncate text-sm text-neutral-400">
                {repoPath.split("/").map((path) => {
                  return (
                    <Link
                      key={path}
                      className="transition-colors hover:text-neutral-200"
                      to={`${path}`}
                    >
                      /{path}
                    </Link>
                  );
                })}
              </span>
            </div>
          </div>

          <div className="relative flex items-center justify-between gap-8 rounded-md border px-2 py-4 shadow-md">
            <span className="w-1/2 truncate">
              {fileType?.lastCommit?.message}
            </span>
            <span className="w-1/2 items-center truncate text-right text-neutral-400">
              {timeAgo(fileType?.lastCommit?.committer.timestamp)}
            </span>
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}

      {fileType?.type === "FOLDER" ? (
        <>
          <div className="rounded-md border shadow-md">
            <FileTree files={files} previousPath={previousPath} />
          </div>

          {fileType.markdown ? (
            <div className="overflow-hidden rounded-md border shadow-md">
              <ReactMarkdown
                transformImageUri={(src) => {
                  const image = images.get(src);

                  if (image) {
                    return image;
                  }

                  return "";
                }}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                className="prose prose-invert max-w-full break-words bg-neutral-800 p-8 lg:prose-xl"
              >
                {fileType.markdown}
              </ReactMarkdown>
            </div>
          ) : null}
        </>
      ) : null}

      {fileType?.type === "FILE" ? (
        <>
          {fileType.file ? (
            <div className="overflow-hidden rounded-md border  shadow-md">
              {fileType.mime?.startsWith("image") ? (
                <img
                  alt={fileType.file as string}
                  src={fileType.file as string}
                />
              ) : (
                <Prism
                  language={
                    languageList
                      .find((language) => {
                        return fileType.extension
                          ? language.extensions?.includes(fileType.extension)
                          : language.name === path.basename(repoPath);
                      })
                      ?.name.toLowerCase() || "text"
                  }
                  showLineNumbers
                  showInlineLineNumbers
                  style={oneDark}
                  className="!m-0"
                >
                  {fileType.file.toString()}
                </Prism>
              )}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default Files;
