import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  web3Accounts,
  web3Enable,
  web3FromAddress,
} from "@polkadot/extension-dapp";
import { AnyJson, ISubmittableResult } from "@polkadot/types/types";
import { hexToU8a } from "@polkadot/util";
import * as d3 from "d3";
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useMeasure from "react-use-measure";

import useAccount from "../../../../stores/account";
import useRPC from "../../../../stores/rpc";
import DynamicContent, { DynamicCodeBlocksType } from "./DynamicContent";

const COLOR_BASE = "#262626";
const COLOR_FILLED = "#22c55e";
const COLOR_CURRENT = "#3b82f6";
const COLOR_LIMIT = "white";
const FONT_SIZE = 14;
const MIN_WIDTH_VISIBLE = 600;
import toast from "react-hot-toast";
import shallow from "zustand/shallow";

import LoadingSpinner from "../../../../components/LoadingSpinner";
import useApi from "../../../../hooks/useApi";

enum ActivityAction {
  VOTE = "VOTE",
  CREATE = "CREATE",
}

const TOTAL = 100;

const Multisig = () => {
  const { id, hash } = useParams();
  const api = useApi();
  const [container, bounds] = useMeasure();
  const chart = useRef<HTMLDivElement>(null);
  const { createApi } = useRPC();
  const { selectedAccount } = useAccount(
    (state) => ({ selectedAccount: state.selectedAccount }),
    shallow
  );
  const [params, setParams] = useState<{
    limit: number;
    filled: number;
    total: number;
    hasVoted: boolean;
  } | null>(null);
  const [content, setContent] = useState<{
    type: DynamicCodeBlocksType;
    data: { method: string; section: string; args: AnyJson };
  } | null>(null);
  const [multisigs, setMultisigs] = useState<
    {
      name: string | null;
      address: string;
      action: ActivityAction;
    }[]
  >([]);
  const [userBalances, setUserBalances] = useState<
    { metadata: string; subTokenId: number | null; weightedBalance: number }[]
  >([]);
  const [selectedBalance, setSelectedBalance] = useState<{
    metadata: string;
    subTokenId: number | null;
    weightedBalance: number;
  } | null>(null);

  if (!id || !hash) return null;

  const getIdentity = async (address: string): Promise<string | null> => {
    const api = await createApi();

    const identity = (
      (await api.query.identity.identityOf(address)).toPrimitive() as {
        info: { display: { raw: string } };
      } | null
    )?.info.display.raw;

    return identity || null;
  };

  const fetchData = async () => {
    const multisigs: {
      name: string | null;
      address: string;
      action: ActivityAction;
    }[] = [];

    const api = await createApi();

    let limit = 100;
    let filled = 0;
    let total = 0;

    const {
      executionThreshold,
      supply,
      defaultAssetWeight,
      defaultPermission,
    } = (await api.query.inv4.ipStorage(parseInt(id))).toPrimitive() as {
      executionThreshold: { One?: null; zeroPoint?: number };
      supply: number;
      defaultAssetWeight: { One?: null; zeroPoint?: number };
      defaultPermission: boolean;
    };

    if (executionThreshold.zeroPoint) {
      limit = executionThreshold.zeroPoint;
    }

    const { signers, originalCaller, actualCall, callMetadata, metadata } = (
      await api.query.inv4.multisig(parseInt(id), hash)
    ).toPrimitive() as {
      signers: [string, number][];
      originalCaller: string;
      actualCall: { method: string; section: string; args: AnyJson };
      callMetadata: string;
      metadata: string | null;
    };

    let meta = null;
    if (metadata) {
      try {
        meta = JSON.parse(metadata) as {
          protocol: string;
          type: string;
        };
      } catch {
        meta = null;
      }
    }

    multisigs.push({
      name: await getIdentity(originalCaller),
      address: originalCaller,
      action: ActivityAction.CREATE,
    });

    const { section, method } = api.registry.findMetaCall(
      hexToU8a(callMetadata)
    );

    total = total + supply;

    const subTokens = (
      await api.query.inv4.subAssets.entries(parseInt(id))
    ).map(([_, subtoken]) => {
      return subtoken.toPrimitive() as { id: number; metadata: string };
    });

    const userBalancesList = [];

    const mainTokenBalance = (
      await api.query.inv4.balance(
        [parseInt(id), null],
        selectedAccount?.address
      )
    ).toPrimitive() as number | null;

    if (mainTokenBalance)
      userBalancesList.push({
        metadata: "main",
        subTokenId: null,
        weightedBalance: mainTokenBalance,
      });

    for (const st of subTokens) {
      const subtoken = st.id;
      const metadata = st.metadata;

      const weightQuery =
        ((
          await api.query.inv4.assetWeight(parseInt(id), subtoken)
        ).toPrimitive() as { One?: null; zeroPoint?: number }) ||
        defaultAssetWeight;

      const weight = weightQuery.zeroPoint ? weightQuery.zeroPoint / 100 : 1;

      total =
        total +
        (await api.query.inv4.balance.entries([parseInt(id), subtoken]))
          .map(([_, balance]) => {
            return (balance.toPrimitive() as number) * weight;
          })
          .reduce((acc, cur) => acc + cur);

      const userb = (
        await api.query.inv4.balance(
          [parseInt(id), subtoken],
          selectedAccount?.address
        )
      ).toPrimitive() as number | null;

      if (userb) {
        const perm = (
          await api.query.inv4.permissions(
            [parseInt(id), subtoken],
            callMetadata
          )
        ).toPrimitive() as boolean | null;

        if (perm || defaultPermission)
          userBalancesList.push({
            metadata,
            subTokenId: subtoken,
            weightedBalance: userb * weight,
          });
      }
    }

    for (const signer of signers) {
      const balance = (
        await api.query.inv4.balance([parseInt(id), signer[1]], signer[0])
      ).toPrimitive() as number;
      filled = filled + balance;

      if (signer[0] != originalCaller) {
        multisigs.push({
          name: await getIdentity(signer[0]),
          address: signer[0],
          action: ActivityAction.VOTE,
        });
      }
    }

    filled = (filled * 100) / total;
    setUserBalances(userBalancesList);
    setSelectedBalance(userBalancesList[0]);

    if (!meta || meta.protocol != "inv4-git") {
      setContent({
        type: DynamicCodeBlocksType.GENERIC,
        data: { method, section, args: actualCall.args },
      });
    } else {
      switch (meta.type) {
        case "push":
          setContent({
            type: DynamicCodeBlocksType.PUSH,
            data: { method, section, args: actualCall.args },
          });
      }
    }

    setParams({
      limit,
      filled,
      total,
      hasVoted: false,
    });

    setMultisigs(multisigs);
  };

  useEffect(() => {
    (async () => {
      if (!params) await fetchData();
      if (!params) return;

      d3.select(chart.current).selectAll("svg").remove();

      const xScale = d3
        .scaleLinear()
        .domain([0, TOTAL])
        .range([0, bounds.width]);

      const svg = d3
        .select(chart.current)
        .append("svg")
        .attr("viewBox", [0, 0, bounds.width, bounds.height])
        .attr("overflow", "visible")
        .attr("width", "100%");

      // BASE
      svg
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", bounds.width)
        .attr("height", bounds.height)
        .attr("fill", COLOR_BASE);

      // FILLED
      svg
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", xScale(params.filled))
        .attr("height", bounds.height)
        .attr("fill", COLOR_FILLED);

      // TEXT FILLED
      svg
        .append("text")
        .attr("x", xScale(params.filled) / 2)
        .attr("y", bounds.height / 2)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .attr("fill", "white")
        .attr("font-size", FONT_SIZE)
        .attr("font-weight", "bold")
        .text(
          bounds.width > MIN_WIDTH_VISIBLE
            ? `${params.filled.toFixed(2)}% in favor`
            : `${params.filled.toFixed(2)}%`
        );

      // CURRENT
      svg
        .append("rect")
        .attr("x", xScale(params.filled))
        .attr("y", 0)
        .attr(
          "width",
          xScale(((selectedBalance?.weightedBalance || 0) * 100) / params.total)
        )
        .attr("height", bounds.height)
        .attr("fill", COLOR_CURRENT);

      // TEXT CURRENT
      svg
        .append("text")
        .attr(
          "x",
          xScale(params.filled) +
            xScale(
              ((selectedBalance?.weightedBalance || 0) * 100) / params.total
            ) /
              2
        )
        .attr("y", -(bounds.height / 2))
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .attr("fill", "white")
        .attr("font-size", FONT_SIZE)
        .attr("font-weight", "bold")
        .text(
          bounds.width > MIN_WIDTH_VISIBLE
            ? `${(
                ((selectedBalance?.weightedBalance || 0) * 100) /
                params.total
              ).toFixed(2)}% of your vote`
            : `${(
                ((selectedBalance?.weightedBalance || 0) * 100) /
                params.total
              ).toFixed(2)}%`
        );

      // LIMIT
      svg
        .append("line")
        .attr("x1", xScale(params.limit))
        .attr("y1", 0)
        .attr("x2", xScale(params.limit))
        .attr("y2", bounds.height)
        .attr("stroke", COLOR_LIMIT)
        .attr("stroke-dasharray", "5, 5");

      // TEXT LIMIT
      svg
        .append("text")
        .attr("x", xScale(params.limit))
        .attr("y", bounds.height * (3 / 2))
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .attr("fill", COLOR_LIMIT)
        .attr("font-size", FONT_SIZE)
        .attr("font-weight", "bold")
        .text(
          bounds.width > MIN_WIDTH_VISIBLE
            ? `${params.limit}% required to pass`
            : `${params.limit}%`
        );
    })();
  }, [bounds.width, bounds.height, params, selectedBalance]);

  const getSignAndSendCallback = () => {
    let hasFinished = false;

    return ({ events, status }: ISubmittableResult) => {
      // prevents error to be displayed multiple times
      if (hasFinished) return;

      if (status.isInvalid) {
        toast.error("Transaction is invalid");

        hasFinished = true;
      } else if (status.isReady) {
        toast.loading("Voting on multisig...");
      } else if (status.isDropped) {
        toast.success("Transaction dropped");

        hasFinished = true;
      } else if (status.isInBlock || status.isFinalized) {
        toast.dismiss();

        const success = events.find(
          ({ event }) =>
            event.method === "MultisigVoteAdded" ||
            event.method === "MultisigExecuted"
        );

        const failed = events.find(
          ({ event }) => event.method === "ExtrinsicFailed"
        );

        if (success) {
          toast.success("Multisig Voted!");
          hasFinished = true;

          setParams(null);
        } else if (failed) {
          toast.error("Transaction failed");

          hasFinished = true;

          console.error(failed.toHuman(true));
        } else throw new Error("UNKNOWN_RESULT");
      }
    };
  };

  const vote = async () => {
    if (!selectedAccount) return;

    await web3Enable("GitArch");

    await web3Accounts();

    const injector = await web3FromAddress(selectedAccount.address);

    await api.tx.inv4
      .voteMultisig([id, selectedBalance?.subTokenId], hash)
      .signAndSend(
        selectedAccount.address,
        { signer: injector.signer },
        getSignAndSendCallback()
      );
  };

  return (
    <>
      {content ? (
        <>
          <div>
            <h1 className="text-xl font-medium leading-6 text-white">
              {(selectedBalance?.weightedBalance || 0) > 0
                ? "Vote on"
                : "Check"}{" "}
              this proposal
            </h1>
            <p className="text-md mt-1 text-neutral-400">
              Read carefully{" "}
              {(selectedBalance?.weightedBalance || 0) > 0
                ? "and vote on this proposal if you wish."
                : null}
            </p>
          </div>

          <DynamicContent content={content} />

          <div>
            <div className="flow-root">
              <ul className="-mb-8">
                {multisigs.map((activity, index) => (
                  <li key={activity.address}>
                    <div className="relative pb-8">
                      {index !== multisigs.length - 1 ? (
                        <span
                          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div className="flex h-8 w-8 items-center justify-center rounded-full">
                          {activity.action == ActivityAction.VOTE ? (
                            <CheckIcon
                              className="h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                          ) : (
                            <UserIcon
                              className="h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div>
                            <p className="text-sm text-gray-400">
                              <a
                                href={`/user/${activity.address}`}
                                className="truncate font-medium text-gray-200"
                              >
                                {activity.name || activity.address}
                              </a>{" "}
                              {activity.action === ActivityAction.VOTE
                                ? "voted to approve this interaction"
                                : "created this interaction"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {(selectedBalance?.weightedBalance || 0) > 0 ? (
            <>
              <div>
                <h3 className="text-xl font-medium leading-6 text-white">
                  Your voting weight
                </h3>
              </div>

              <div className="my-4 h-10 w-full" ref={container}>
                <div ref={chart} />
              </div>
            </>
          ) : null}

          {userBalances.length > 0 ? (
            <div>
              Vote as:
              <div className="flex flex-col gap-4">
                <Listbox value={selectedBalance} onChange={setSelectedBalance}>
                  <div className="relative z-10">
                    <Listbox.Button className="relative w-40 cursor-pointer rounded-md border bg-neutral-900 px-4 py-2 pr-10 text-left shadow-md focus:outline-none focus-visible:border-neutral-500 focus-visible:ring-2 focus-visible:ring-neutral-900 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className="block truncate">
                        {" "}
                        {selectedBalance?.metadata}{" "}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-4 w-4 text-neutral-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute max-h-60 overflow-auto rounded-md bg-neutral-900 py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {userBalances.map((token) => (
                          <Listbox.Option
                            key={token.subTokenId}
                            className={({ active }) =>
                              `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                active
                                  ? "bg-neutral-100 text-neutral-900"
                                  : null
                              }`
                            }
                            value={token}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {token.metadata}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-600">
                                    <CheckIcon
                                      className="h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
                <div>
                  <button
                    onClick={vote}
                    className="items-center rounded-md border border-transparent bg-neutral-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
                  >
                    {(params?.filled || 0) +
                      ((selectedBalance?.weightedBalance || 0) * 100) /
                        (params?.total || 0) >
                    (params?.limit || 100)
                      ? "Approve"
                      : "Vote"}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default Multisig;
