import { Menu } from "@headlessui/react";
import {
  FingerPrintIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import logoFull from "../../assets/logo-full.svg";
import logoIcon from "../../assets/logo-icon.svg";
import useConnect from "../../hooks/useConnect";
import useAccount from "../../stores/account";
import useModal, { ModalName } from "../../stores/modals";

const Header = () => {
  const { setOpenModal } = useModal(
    (state) => ({ setOpenModal: state.setOpenModal }),
    shallow
  );
  const { handleConnect } = useConnect();
  const { selectedAccount } = useAccount(
    (state) => ({ selectedAccount: state.selectedAccount }),
    shallow
  );
  const navigate = useNavigate();

  return (
    <nav className="bg-neutral-800">
      <div className="mx-auto max-w-7xl px-2 xl:px-0">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex items-center px-2 lg:px-0">
            <div className="flex-shrink-0">
              <Link to="/">
                <img
                  className="block h-12 w-auto lg:hidden"
                  src={logoIcon}
                  alt="GitArch Logo"
                />
                <img
                  className="hidden h-12 w-auto lg:block"
                  src={logoFull}
                  alt="GitArch Logo"
                />
              </Link>
            </div>
          </div>

          <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
            <div className="w-full max-w-lg lg:max-w-xs">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-neutral-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full rounded-md border border-transparent bg-neutral-700 py-2 pl-10 pr-3 leading-5 text-neutral-300 placeholder-neutral-400 focus:border-white focus:bg-white focus:text-neutral-900 focus:outline-none focus:ring-white sm:text-sm"
                  placeholder="Search for Repository ID"
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`/repo/${e.currentTarget.value}`);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="ml-4 lg:block">
            <div className="flex items-center gap-4">
              <button
                onClick={() => {
                  setOpenModal({ name: ModalName.NEW_REPO });
                }}
                className=" flex flex-shrink-0 items-center rounded-md bg-neutral-800 p-1 text-neutral-400 transition-colors hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-neutral-800"
              >
                <PlusIcon className="h-4 w-4" aria-hidden="true" />
              </button>

              {selectedAccount ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex flex-shrink-0 rounded-md bg-neutral-800 p-1 text-neutral-400 transition-colors hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-neutral-800">
                      {selectedAccount ? (
                        selectedAccount.meta.name || selectedAccount.address
                      ) : (
                        <>
                          <FingerPrintIcon
                            className="h-6 w-6 "
                            aria-hidden="true"
                          />
                          <span className="ml-2">Log In</span>
                        </>
                      )}
                    </Menu.Button>
                  </div>
                  <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right divide-y divide-neutral-100 rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-neutral-800 text-white"
                              : "text-neutral-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors`}
                          onClick={() =>
                            navigate(`/user/${selectedAccount.address}`)
                          }
                        >
                          Profile
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? "bg-neutral-800 text-white"
                              : "text-neutral-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={handleConnect}
                        >
                          Change Account
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              ) : (
                <button
                  type="button"
                  className="flex flex-shrink-0 rounded-md bg-neutral-800 p-1 text-neutral-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-neutral-800"
                  onClick={handleConnect}
                >
                  <>
                    <FingerPrintIcon className="h-6 w-6" aria-hidden="true" />
                    <span className="ml-2">Log In</span>
                  </>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
