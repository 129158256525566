import git from "isomorphic-git";

import fs from "./fs";

const decoder = new TextDecoder();

export const getGitDiff = async ({
  id,
  oldHash,
  newHash,
}: {
  id?: string;
  oldHash?: string;
  newHash: string;
}): Promise<
  {
    path: string;
    type: string;
    originalCode?: string;
    newCode: string;
  }[]
> => {
  return git.walk({
    fs,
    dir: `/${id}`,
    trees: [git.TREE({ ref: oldHash }), git.TREE({ ref: newHash })],
    map: async function (filepath, [A, B]) {
      // ignore directories
      if (filepath === ".") {
        return;
      }

      if (!A || !B) {
        return;
      }

      if ((await A.type()) === "tree" || (await B.type()) === "tree") {
        return;
      }

      // generate ids
      const Aoid = await A.oid();
      const Boid = await B.oid();

      // determine modification type
      let type = "equal";

      if (Aoid !== Boid) {
        type = "modify";
      }

      if (Aoid === undefined) {
        type = "add";
      }

      if (Boid === undefined) {
        type = "remove";
      }

      if (Aoid === undefined && Boid === undefined) {
        console.error("Something weird happened:", {
          A,
          B,
        });

        throw new Error("SOMETHING_WENT_WRONG");
      }

      if (type === "equal") return;

      const newCode = await B.content();

      if (!newCode) throw new Error("NEW_CODE_NOT_FOUND");

      const originalCode = await A.content();

      return {
        path: `/${filepath}`,
        type: type,
        originalCode: originalCode ? decoder.decode(originalCode) : undefined,
        newCode: decoder.decode(newCode),
      };
    },
  });
};
