import { DocumentIcon, FolderIcon } from "@heroicons/react/24/outline";
import { CommitObject, WalkerEntry } from "isomorphic-git";
import { Link } from "react-router-dom";

import { timeAgo } from "../../../utils/timeAgo";

const formatDisplayPath = (filePath: string): string => {
  const parts = filePath.split("/");

  return parts[parts.length - 1];
};

const generateFileIcon = (fileType: "FILE" | "DIRECTORY") => {
  switch (fileType) {
    case "DIRECTORY":
      return <FolderIcon className="h-5 w-5 shrink-0 text-gray-400" />;
    case "FILE":
      return <DocumentIcon className="h-5 w-5 shrink-0 text-gray-400" />;
    default:
      return null;
  }
};

const FileTree = ({
  files,
  previousPath,
}: {
  files: {
    entry: WalkerEntry;
    filepath: string;
    lastCommit: CommitObject;
    fileType: "FILE" | "DIRECTORY";
  }[];
  previousPath: string | null;
}) => {
  return (
    <ul className="divide-y divide-neutral-200">
      {previousPath !== null ? (
        <li key={previousPath} className="relative px-2 py-4">
          <Link to={previousPath} className="h-full w-full">
            <span className="absolute inset-0" aria-hidden="true" />
            <span>{".."}</span>
          </Link>
        </li>
      ) : null}

      {files.map((file) => {
        return (
          <li key={file.filepath} className="relative truncate px-2 py-4">
            <Link
              to={file.filepath}
              className="flex h-full w-full gap-4 transition-colors hover:text-neutral-300"
            >
              <span className="absolute inset-0" aria-hidden="true" />

              <span className="flex w-1/3 items-center gap-2 truncate">
                {generateFileIcon(file.fileType)}{" "}
                {formatDisplayPath(file.filepath)}
              </span>

              <span className="w-1/3 truncate text-neutral-400">
                {file.lastCommit.message}
              </span>

              <span className="w-1/3 truncate text-right text-neutral-400">
                {timeAgo(file.lastCommit.committer.timestamp)}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default FileTree;
