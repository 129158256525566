import git, { CommitObject } from "isomorphic-git";

import { MultiObject } from "./codec";
import fs from "./fs";

const getCommits = async (id: string | undefined, ref: string) => {
  const commits = await git.log({
    fs,
    dir: `/${id}`,
    ref,
  });

  return commits.map(({ oid, commit }) => ({
    hash: oid,
    commit: commit,
  }));
};

const getCommitsInMultiobject = async (
  id: string | undefined,
  hash: string,
  multiObject: MultiObject,
  commitList: { hash: string; commit: CommitObject }[]
): Promise<{ hash: string; commit: CommitObject }[]> => {
  const object = multiObject.objects.get(hash);

  if (object) {
    const commit = (
      await git.log({
        fs,
        dir: `/${id}`,
        depth: 1,
        ref: hash,
      })
    )[0].commit;

    commitList.push({ hash, commit });

    return await getCommitsInMultiobject(
      id,
      commit.parent[0],
      multiObject,
      commitList
    );
  } else {
    return commitList;
  }
};

const getCommitsBetween = async (
  id: string | undefined,
  lowest: string,
  highest: string,
  commitList: { hash: string; commit: CommitObject }[]
): Promise<{ hash: string; commit: CommitObject }[] | null> => {
  if (!lowest || !highest) return null;

  if (lowest != highest) {
    const commit = (
      await git.log({
        fs,
        dir: `/${id}`,
        depth: 1,
        ref: highest,
      })
    )[0].commit;

    commitList.push({ hash: highest, commit });

    return await getCommitsBetween(id, lowest, commit.parent[0], commitList);
  } else {
    return commitList;
  }
};

export { getCommits, getCommitsBetween, getCommitsInMultiobject };
