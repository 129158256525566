import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  web3Accounts,
  web3Enable,
  web3FromAddress,
} from "@polkadot/extension-dapp";
import { ISubmittableResult } from "@polkadot/types/types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import useApi from "../hooks/useApi";
import useAccount from "../stores/account";
import useModal from "../stores/modals";
import { sendToCrustGetCid } from "../utils/crust";

type UserInfo = {
  display?: string;
  legal?: string;
  web?: string;
  riot?: string;
  email?: string;
  twitter?: string;
  image?: FileList;
};

const EditIdentity = ({ isOpen }: { isOpen: boolean }) => {
  const { setOpenModal, metadata } = useModal((state) => ({
    setOpenModal: state.setOpenModal,
    metadata: state.metadata,
  }));
  const { selectedAccount, crustSignatures } = useAccount(
    (state) => ({
      selectedAccount: state.selectedAccount,
      crustSignatures: state.crustSignatures,
    }),
    shallow
  );
  const [isLoading, setLoading] = useState(false);
  const registrationForm = useForm<UserInfo>();
  const api = useApi();
  const navigate = useNavigate();

  const getSignAndSendCallback = () => {
    let hasFinished = false;

    return ({ events, status }: ISubmittableResult) => {
      // prevents error to be displayed multiple times
      if (hasFinished) {
        setLoading(false);

        return;
      }

      if (status.isInvalid) {
        toast.error("Transaction is invalid");

        hasFinished = true;
      } else if (status.isReady) {
        toast.loading("Editing identity...");
      } else if (status.isDropped) {
        toast.error("Transaction dropped");

        hasFinished = true;

        setLoading(false);
      } else if (status.isInBlock || status.isFinalized) {
        toast.dismiss();

        const success = events.find(
          ({ event }) => event.method === "IdentitySet"
        );

        const failed = events.find(
          ({ event }) => event.method === "ExtrinsicFailed"
        );

        if (success) {
          toast.success("Identity edited!");
          hasFinished = true;

          setLoading(false);

          navigate(0);
        } else if (failed) {
          toast.error("Transaction failed");

          hasFinished = true;

          setLoading(false);

          console.error(failed.toHuman(true));
        } else throw new Error("UNKNOWN_RESULT");
      }
    };
  };

  const setIdentity = async (identity: UserInfo) => {
    if (!selectedAccount) return;

    await web3Enable("GitArch");

    await web3Accounts();

    const injector = await web3FromAddress(selectedAccount.address);

    try {
      await api.tx.identity
        .setIdentity({
          additional: [],
          ...identity,
        })
        .signAndSend(
          selectedAccount.address,
          { signer: injector.signer },
          getSignAndSendCallback()
        );
    } catch (e) {
      console.error(e);

      setLoading(false);
    }
  };

  const handleSubmit = registrationForm.handleSubmit(
    async ({ display, legal, web, riot, email, twitter, image }) => {
      if (!selectedAccount) return;

      setLoading(true);

      const identity: {
        [key: string]:
          | { raw: string; sha256?: never }
          | { raw?: never; sha256?: Uint8Array | string };
      } = {};

      if (display) identity.display = { raw: display };
      if (legal) identity.legal = { raw: legal };
      if (web) identity.web = { raw: web };
      if (riot) identity.riot = { raw: riot };
      if (email) identity.email = { raw: email };
      if (twitter) identity.twitter = { raw: twitter };

      if (image && image.length > 0) {
        const imageToastId = toast.loading("Uploading image...");

        if (!crustSignatures[selectedAccount.address]) return;

        const file = image[0];

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async (e) => {
          const data = e.target?.result;

          if (data) {
            const base64 = data.toString().split(",")[1];

            const binData = Uint8Array.from(atob(base64), (c) =>
              c.charCodeAt(0)
            );

            const cid = await sendToCrustGetCid(
              crustSignatures[selectedAccount.address],
              [
                {
                  data: binData,
                  metadata: file.name,
                },
              ]
            );

            toast.dismiss(imageToastId);

            toast.success("Image uploaded!");

            if (cid[0]) {
              identity.image = {
                sha256: cid[0].multihash.digest,
              };
            }

            setIdentity(identity);
          }
        };

        return;
      } else {
        identity.image = {
          sha256: metadata?.user?.image,
        };

        setIdentity(identity);
      }

      setIdentity(identity);
    }
  );

  useEffect(() => {
    registrationForm.reset({
      display: metadata?.user?.display,
      legal: metadata?.user?.legal,
      web: metadata?.user?.web,
      riot: metadata?.user?.riot,
      email: metadata?.user?.email,
      twitter: metadata?.user?.twitter,
    });
  }, [metadata]);

  return (
    <Dialog open={isOpen} onClose={() => setOpenModal({ name: null })}>
      <Dialog.Overlay className="fixed inset-0 z-40 h-screen w-full bg-black/40 backdrop-blur-md" />

      <button className="pointer fixed top-0 right-0 z-50 flex cursor-pointer flex-col items-center justify-center bg-transparent bg-opacity-50 p-6 text-gray-100 outline-none duration-500 hover:bg-opacity-100 hover:opacity-30">
        <XMarkIcon className="h-5 w-5" />
        <span className="block">close</span>
      </button>
      <Dialog.Panel>
        <div className="fixed left-1/2 top-1/2 z-50 mx-auto block max-h-[calc(100%-2rem)] w-[calc(100%-2rem)] max-w-lg -translate-x-1/2 -translate-y-1/2 transform flex-col overflow-auto rounded-md border border-gray-50 bg-neutral-900 p-6 sm:w-full">
          <h2 className="text-xl font-bold text-white">Edit Identity</h2>

          <form onSubmit={handleSubmit}>
            <div className="mt-2 grid grid-cols-6 gap-6 rounded-md">
              <div className="col-span-6 lg:col-span-3">
                <label
                  htmlFor="display"
                  className="block text-sm font-medium text-neutral-400"
                >
                  Display Name
                </label>
                <input
                  type="text"
                  {...registrationForm.register("display", {
                    required: true,
                  })}
                  className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <label
                  htmlFor="legal"
                  className="block text-sm font-medium text-neutral-400"
                >
                  Legal Name
                </label>
                <input
                  type="text"
                  {...registrationForm.register("legal", {})}
                  className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <label
                  htmlFor="web"
                  className="block text-sm font-medium text-neutral-400"
                >
                  Web Address
                </label>
                <input
                  type="text"
                  {...registrationForm.register("web", {})}
                  className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <label
                  htmlFor="riot"
                  className="block text-sm font-medium text-neutral-400"
                >
                  Riot
                </label>
                <input
                  type="text"
                  {...registrationForm.register("riot", {})}
                  className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-neutral-400"
                >
                  Email Address
                </label>
                <input
                  type="text"
                  {...registrationForm.register("email", {})}
                  className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <label
                  htmlFor="twitter"
                  className="block text-sm font-medium text-neutral-400"
                >
                  Twitter Handle
                </label>
                <div className="mt-1 flex rounded-md text-white shadow-sm">
                  <span className="inline-flex items-center rounded-l-md border border-r-0 border-neutral-300 bg-neutral-900 px-3 text-sm text-white">
                    @
                  </span>
                  <input
                    type="text"
                    {...registrationForm.register("twitter", {})}
                    className="block w-full rounded-r-md border border-neutral-300 bg-neutral-900 py-2 px-3 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="col-span-6">
                <label
                  htmlFor="image"
                  className="block text-sm font-medium text-neutral-400"
                >
                  Image{" "}
                </label>
                <input
                  type="file"
                  accept="image/*"
                  {...registrationForm.register("image", {})}
                  className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-amber-400 py-2 px-4 text-sm font-bold text-neutral-900 shadow-sm transition-colors hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
                  disabled={isLoading}
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default EditIdentity;
