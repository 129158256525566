import { Listbox, Popover, Transition } from "@headlessui/react";
import {
  ChevronUpDownIcon,
  ClipboardIcon,
  CloudArrowUpIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import git from "isomorphic-git";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import shallow from "zustand/shallow";

import Head from "../../components/Head";
import LoadingSpinner from "../../components/LoadingSpinner";
import useApi from "../../hooks/useApi";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import useModal, { ModalName } from "../../stores/modals";
import useRepo from "../../stores/repo";
import classNames from "../../utils/classNames";
import {
  $MultiObject,
  $RepoData,
  GitObject,
  MultiObject,
  RepoData,
} from "../../utils/codec";
import { decompressData } from "../../utils/compression";
import fs from "../../utils/fs";
import generateRepoPath from "../../utils/generateRepoPath";
import { getIpfList, getIpfsData } from "../../utils/ipf";
import Commits from "./Commits";
import Files from "./Files";
import Multisigs from "./Multisigs";
import Setup from "./Setup";

enum Tab {
  FILES = "Files",
  COMMITS = "Commits",
  ISSUES = "Issues",
  INTERACTIONS = "Interactions",
  SETTINGS = "Settings",
}

const defaultTabs = [
  { name: Tab.FILES, path: "tree", active: true },
  { name: Tab.COMMITS, path: "commits", active: true },
  { name: Tab.ISSUES, path: "issues", active: false },
  { name: Tab.INTERACTIONS, path: "multisig", active: true },
  { name: Tab.SETTINGS, path: "settings", active: false },
];

const CloneRepoGuide = ({ id }: { id: string }) => {
  const [, copyText] = useCopyToClipboard();

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Popover className="relative ">
      <Popover.Button className="flex cursor-pointer items-center justify-center gap-2 rounded-md border bg-neutral-900 p-4 text-left shadow-md transition-colors hover:bg-neutral-800 focus:outline-none focus-visible:border-neutral-500 focus-visible:ring-2 focus-visible:ring-neutral-900 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
        <DocumentDuplicateIcon className="h-5 w-5 text-white" />
        <span>Clone</span>
      </Popover.Button>

      <Popover.Panel className="absolute right-0 z-10 mt-2 w-max rounded-md border bg-neutral-900 p-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h3 className="text-lg">Clone this repo</h3>

            <p className="text-xs">Use this command to clone the repo</p>
          </div>

          <div className="flex items-center justify-center gap-4">
            <div className="rounded-md bg-neutral-600 p-2 font-mono" ref={ref}>
              git clone inv4://{id}
            </div>{" "}
            <button
              className="cursor-pointer rounded-md bg-neutral-800 p-2 text-neutral-200 transition-colors hover:bg-neutral-400"
              onClick={() => {
                if (ref.current?.innerText) {
                  copyText(ref.current?.innerText);

                  toast.success("Copied to clipboard!");
                }
              }}
            >
              <ClipboardIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

const ForkRepo = ({
  id,
  name,
  description,
}: {
  id: string;
  name: string;
  description: string;
}) => {
  const setOpenModal = useModal((state) => state.setOpenModal);

  const handleClick = useCallback(async () => {
    setOpenModal({
      name: ModalName.NEW_REPO,
      metadata: {
        fork: {
          id,
          name,
          description,
        },
      },
    });
  }, [id]);

  return (
    <button
      onClick={handleClick}
      className="flex cursor-pointer items-center justify-center gap-2 rounded-md border bg-neutral-900 p-4 text-left shadow-md transition-colors hover:bg-neutral-800 focus:outline-none focus-visible:border-neutral-500 focus-visible:ring-2 focus-visible:ring-neutral-900 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
    >
      <CloudArrowUpIcon className="h-5 w-5 text-white" />
      <span>Fork</span>
    </button>
  );
};

const getMultiObject = async (
  hash: string,
  ipfList: Array<{ metadata: string; data: string }>
): Promise<MultiObject> => {
  const ipf = ipfList.find((ipf) => ipf.metadata === hash);

  const data = await getIpfsData((ipf?.data + "").replace("0x", ""));

  const finalData = await decompressData(data);

  const multiObject: MultiObject = $MultiObject.decode(finalData);

  return multiObject;
};

const Repo = () => {
  const { id, ...route } = useParams();
  const repoPath = generateRepoPath(route["*"]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const api = useApi();
  const location = useLocation();
  const {
    setRepoData,
    setRepoDataId,
    setBranches,
    setRepoPath,
    setRepoMetadata,
    setSelectedBranch,
    repoMetadata,
    branches,
    selectedBranch,
  } = useRepo(
    (state) => ({
      setRepoData: state.setRepoData,
      setRepoDataId: state.setRepoDataId,
      repoMetadata: state.repoMetadata,
      setRepoMetadata: state.setRepoMetadata,
      setBranches: state.setBranches,
      setSelectedBranch: state.setSelectedBranch,
      setRepoPath: state.setRepoPath,
      branches: state.branches,
      selectedBranch: state.selectedBranch,
    }),
    shallow
  );
  const [tabs, setTabs] = useState(defaultTabs);

  if (!id) {
    navigate("/404", { replace: true });

    throw new Error("ID_NOT_FOUND");
  }

  const multiObjectList = new Map();

  const alreadyWritten: string[] = [];

  const getObject = async (
    oid: string,
    objectMapping: Map<string, string>
  ): Promise<GitObject> => {
    const multiObjectHash = objectMapping.get(oid) + "";

    const multiObject = multiObjectList.get(multiObjectHash);

    const object = multiObject.objects.get(oid);

    return object;
  };

  const recursiveObjectWalker = async (
    object: GitObject,
    objectMapping: Map<string, string>
  ) => {
    const type = () => {
      switch (object.metadata._tag) {
        case "Commit":
          return "commit";
        case "Tag":
          return "tag";
        case "Tree":
          return "tree";
        case "Blob":
          return "blob";
      }
    };

    await git.writeObject({
      fs,
      dir: `/${id}`,
      format: "content",
      oid: object.git_hash,
      object: object.data,
      type: type(),
    });

    const { metadata } = object;

    if (metadata._tag === "Commit") {
      if (!alreadyWritten.includes(metadata.tree_git_hash)) {
        const obj = await getObject(metadata.tree_git_hash, objectMapping);
        await recursiveObjectWalker(obj, objectMapping);
        alreadyWritten.push(metadata.tree_git_hash);
      }

      for (const hash of metadata.parent_git_hashes) {
        if (!alreadyWritten.includes(hash)) {
          const obj = await getObject(hash, objectMapping);
          await recursiveObjectWalker(obj, objectMapping);
          alreadyWritten.push(hash);
        }
      }
    }

    if (metadata._tag === "Tag") {
      if (!alreadyWritten.includes(metadata.target_git_hash)) {
        const obj = await getObject(metadata.target_git_hash, objectMapping);
        await recursiveObjectWalker(obj, objectMapping);
        alreadyWritten.push(metadata.target_git_hash);
      }
    }

    if (metadata._tag === "Tree") {
      for (const hash of metadata.entry_git_hashes) {
        if (!alreadyWritten.includes(hash)) {
          const obj = await getObject(hash, objectMapping);
          await recursiveObjectWalker(obj, objectMapping);
          alreadyWritten.push(hash);
        }
      }
    }
  };

  const processObjects = async (
    refs: string[],
    objectMapping: Map<string, string>
  ) => {
    for (const ref of refs) {
      const hash = objectMapping.get(ref) + "";

      const multiObject = multiObjectList.get(hash);

      const object = multiObject.objects.get(ref);

      await recursiveObjectWalker(object, objectMapping);
    }
  };

  const handleInitialization = useCallback(async () => {
    setLoading(true);

    try {
      await git.init({
        fs,
        dir: `/${id}`,
      });

      const ips = (
        await api.query.inv4.ipStorage(parseInt(id))
      ).toPrimitive() as { metadata: string; data: { ipfId: number }[] };

      if (!ips) {
        throw new Error("QUERY_NOT_FOUND");
      }

      const ipfIds = ips.data.map((data) => data.ipfId);

      const ipfList = await getIpfList({ api, data: ipfIds });

      if (ipfList.length === 0) {
        setLoading(false);

        navigate(`/repo/${id}/setup`, { replace: true });

        setTabs((tabs) =>
          tabs.map((tab) => ({
            ...tab,
            active: false,
          }))
        );

        return;
      }

      const repoDataIpf = ipfList.find((ipf) => ipf.metadata === "RepoData");

      if (!repoDataIpf) return;

      const fetchingToastId = toast.loading("Fetching repo...");

      const data = await getIpfsData(repoDataIpf.data.replace("0x", ""));

      const finalData = await decompressData(data);

      const repoData: RepoData = $RepoData.decode(finalData);

      const refs = Array.from(repoData.refs.values());

      const objects = repoData.objects;

      const values = [...new Set(Array.from(repoData.objects.values()))];

      const promises = [];

      for (const value of values) {
        promises.push(
          getMultiObject(value, ipfList).then((multiObject) =>
            multiObjectList.set(value, multiObject)
          )
        );
      }

      await Promise.all(promises);

      toast.dismiss(fetchingToastId);

      toast.success("Repo fetched successfully!");

      await processObjects(refs, objects);

      for await (const [ref, value] of repoData.refs) {
        await git.writeRef({
          fs,
          dir: `/${id}`,
          ref,
          value,
          force: true,
          symbolic: false,
        });
      }

      const branches = await git.listBranches({ fs, dir: `/${id}` });

      await git.checkout({
        fs,
        dir: `/${id}`,
        ref: branches[0],
      });

      let metadata = null;

      try {
        metadata = JSON.parse(ips.metadata);
      } catch (e) {
        console.error(e);
      }

      if (metadata) {
        setRepoMetadata(metadata);
      }

      setBranches(branches);

      setSelectedBranch(branches[0]);

      setRepoData(repoData);
      setRepoDataId(repoDataIpf.id);

      setLoading(false);
    } catch (e) {
      console.error(e);

      setLoading(false);

      toast.dismiss();

      toast.error("Error fetching repo");
    }
  }, [id]);

  useEffect(() => {
    handleInitialization();
  }, [handleInitialization]);

  useEffect(() => {
    setRepoPath(repoPath);
  }, [repoPath, id]);

  return (
    <>
      <Head
        title={repoMetadata?.repoName || `Repository ${id}`}
        description={repoMetadata?.repoDescription || ""}
      />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="mx-auto flex max-w-7xl flex-col gap-8 px-2 sm:px-8 xl:p-0">
          {repoMetadata ? (
            <div className="flex flex-col items-center gap-8 md:flex-row md:justify-between">
              <Link to={`/repo/${id}/tree`} className="w-full  md:w-auto">
                <h1 className="text-2xl font-bold">{repoMetadata.repoName}</h1>

                <p className="text-gray-400">{repoMetadata.description}</p>
              </Link>

              <div className="flex w-full items-center justify-between gap-4 md:w-auto">
                <Listbox value={selectedBranch} onChange={setSelectedBranch}>
                  <div className="relative z-10 w-full">
                    <Listbox.Button className="relative w-full cursor-pointer rounded-md border bg-neutral-900 py-4 px-2 pr-10 text-left shadow-md transition-colors hover:bg-neutral-800 focus:outline-none focus-visible:border-neutral-500 focus-visible:ring-2 focus-visible:ring-neutral-900 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:w-72 sm:text-sm">
                      <span className="block truncate">{selectedBranch}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-4 w-4 text-neutral-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute right-0 z-20 mt-2 max-h-60 w-full overflow-auto rounded-md border bg-neutral-900 p-1 py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {branches.map((branch) => (
                          <Listbox.Option
                            key={branch}
                            className={({ active }) =>
                              `relative cursor-pointer select-none rounded-md py-2 px-4 transition-colors ${
                                active
                                  ? "bg-neutral-100  text-neutral-900"
                                  : null
                              }`
                            }
                            value={branch}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {branch}
                                </span>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>

                <CloneRepoGuide id={id} />

                <ForkRepo
                  name={repoMetadata.repoName}
                  description={repoMetadata.description}
                  id={id}
                />
              </div>
            </div>
          ) : null}
          <div>
            <div className="border-b border-neutral-200">
              <nav className="-mb-px flex" aria-label="Tabs">
                {tabs.map((tab) => (
                  <Link
                    key={tab.name}
                    className={classNames(
                      location.pathname.includes(`repo/${id}/${tab.path}`)
                        ? "border-neutral-100 text-neutral-100"
                        : "border-transparent text-neutral-400 transition-colors hover:border-neutral-300 hover:text-neutral-300",
                      tab.active ? "cursor-pointer" : "cursor-not-allowed",
                      "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium"
                    )}
                    to={tab.active ? tab.path : "#"}
                  >
                    {tab.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>

          <Routes>
            <Route index element={<Navigate to="tree" replace={true} />} />

            <Route path="tree/*" element={<Files />} />

            <Route path="commits/*" element={<Commits />} />

            <Route path="multisig/*" element={<Multisigs />} />

            <Route path="setup" element={<Setup />} />
          </Routes>
        </div>
      )}
    </>
  );
};

export default Repo;
