import * as $ from "parity-scale-codec";

const $RepoData = $.object(
  ["refs", $.map($.str, $.str)],
  ["objects", $.map($.str, $.str)]
);

type RepoData = $.Native<typeof $RepoData>;

const $GitObjectMetadata = $.taggedUnion("_tag", [
  ["Commit", ["parent_git_hashes", $.set($.str)], ["tree_git_hash", $.str]],
  ["Tag", ["target_git_hash", $.str]],
  ["Tree", ["entry_git_hashes", $.set($.str)]],
  ["Blob"],
]);

const $GitObject = $.object(
  ["git_hash", $.str],
  ["data", $.uint8array],
  ["metadata", $GitObjectMetadata]
);

type GitObject = $.Native<typeof $GitObject>;

const $MultiObject = $.object(
  ["hash", $.str],
  ["git_hashes", $.array($.str)],
  ["objects", $.map($.str, $GitObject)]
);

type MultiObject = $.Native<typeof $MultiObject>;

const $StringVec = $.array($.str);
type StringVec = $.Native<typeof $StringVec>;

export { $GitObject, $MultiObject, $RepoData, $StringVec };

export type { GitObject, MultiObject, RepoData, StringVec };
