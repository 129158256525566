import { ApiPromise } from "@polkadot/api";
import {
  web3Accounts,
  web3Enable,
  web3FromAddress,
} from "@polkadot/extension-dapp";
import { ISubmittableResult } from "@polkadot/types/types";
import toast from "react-hot-toast";

const createMergeMultisig = async (
  api: ApiPromise,
  signer: string,
  id: number,
  fromBranch: string,
  toBranch: string,
  newHead: string,
  newData: { metadata: string; data: Uint8Array }[],
  oldRepoDataId: number
) => {
  await web3Enable("GitArch");

  await web3Accounts();

  const injector = await web3FromAddress(signer);

  const txs = [];

  for (const ipf of newData) {
    txs.push(api.tx.ipf.mint(ipf.metadata, ipf.data));
  }

  let finished = false;

  await api.tx.utility
    .batchAll(txs)
    .signAndSend(
      signer,
      { signer: injector.signer },
      async ({ events, status }: ISubmittableResult) => {
        if (finished) return;
        if (status.isInvalid) {
          toast.error("Transaction is invalid");
        } else if (status.isReady) {
          toast.loading("Minting NFTs...");
        } else if (status.isDropped) {
          toast.error("Transaction dropped");
        } else if (status.isInBlock || status.isFinalized) {
          toast.dismiss();

          const failed = events.find(
            ({ event }) => event.method === "ExtrinsicFailed"
          );

          const newNFTs = events
            .filter(({ event }) => event.method === "Minted")
            .map(
              ({ event }) => (event.toPrimitive() as { data: string[] }).data[1]
            );

          if (newNFTs.length == 2) {
            toast.success("NFTs minted!");

            const newNftIds = newNFTs.map((id) => parseInt(id));

            await operateMultisig(
              api,
              signer,
              id,
              fromBranch,
              toBranch,
              newHead,
              newNftIds[0],
              newNftIds[1],
              oldRepoDataId
            );

            finished = true;
            return;
          } else if (failed) {
            toast.error("Merge failed.");

            console.error(failed.toHuman(true));
          } else throw new Error("UNKNOWN_RESULT");
        }
      }
    );
};

const operateMultisig = async (
  api: ApiPromise,
  signer: string,
  id: number,
  fromBranch: string,
  toBranch: string,
  newHead: string,
  newRepoDataId: number,
  newMultiobjectId: number,
  oldRepoDataId: number
) => {
  await web3Enable("GitArch");

  await web3Accounts();

  const injector = await web3FromAddress(signer);

  await api.tx.inv4
    .operateMultisig(
      true,
      [id, null],
      JSON.stringify({
        protocol: "inv4-git",
        type: "merge",
        from: fromBranch,
        to: toBranch,
        newHead,
      }),
      api.tx.utility.batchAll([
        api.tx.inv4.remove(
          id,
          signer,
          [[api.createType("AnyId", { IpfId: oldRepoDataId }), signer]],
          null
        ),
        api.tx.inv4.append(
          id,
          signer,
          [
            api.createType("AnyId", { IpfId: newMultiobjectId }),
            api.createType("AnyId", { IpfId: newRepoDataId }),
          ],
          null
        ),
      ])
    )
    .signAndSend(
      signer,
      { signer: injector.signer },
      ({ events, status }: ISubmittableResult) => {
        if (status.isInvalid) {
          toast.error("Transaction is invalid");
        } else if (status.isReady) {
          toast.loading("Merging branch...");
        } else if (status.isDropped) {
          toast.error("Transaction dropped");
        } else if (status.isInBlock || status.isFinalized) {
          toast.dismiss();
          const multisigVoteStarted = events.find(
            ({ event }) => event.method === "MultisigVoteStarted"
          );

          const multisigExecuted = events.find(
            ({ event }) => event.method === "MultisigExecuted"
          );

          const failed = events.find(
            ({ event }) => event.method === "ExtrinsicFailed"
          );

          if (multisigExecuted) {
            toast.success("Merge performed!");
          } else if (multisigVoteStarted) {
            toast.success("Merge request opened!");
          } else if (failed) {
            toast.error("Merge failed.");

            console.error(failed.toHuman(true));
          } else throw new Error("UNKNOWN_RESULT");
        }
      }
    );
};

export { createMergeMultisig };
