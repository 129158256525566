import { Helmet } from "react-helmet-async";

import image from "../assets/og-image.jpg";

const metadata = {
  title: "GitArch",
  image: image,
  social: {
    twitter: "@gitarch",
  },
};

const Head = ({
  title,
  description,
  image = metadata.image,
}: {
  title: string;
  description: string;
  image?: string;
}) => {
  return (
    <Helmet>
      <title>
        {title} - {metadata.title}
      </title>

      <meta name="description" content={description} />
      <meta name="image" content={image} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={metadata.social.twitter} />
      <meta name="twitter:creator" content={metadata.social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <link rel="canonical" href={`/${metadata.image}`} />
    </Helmet>
  );
};

export default Head;
