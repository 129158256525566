const licenses = [
  "Apache2",
  "GPLv3",
  "GPLv2",
  "AGPLv3",
  "LGPLv3",
  "MIT",
  "ISC",
  "MPLv2",
  "BSLv1",
  "Unlicense",
  "CC0_1",
  "CC_BY_4",
  "CC_BY_SA_4",
  "CC_BY_ND_4",
  "CC_BY_NC_4",
  "CC_BY_NC_SA_4",
  "CC_BY_NC_ND_4",
  "OFL_1_1",
  "NFT_License_2",
];

export default licenses;
