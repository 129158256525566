import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import git from "isomorphic-git";
import { useCallback, useEffect, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { useParams } from "react-router-dom";

import LoadingSpinner from "../../../../components/LoadingSpinner";
import fs from "../../../../utils/fs";
import { getGitDiff } from "../../../../utils/getGitDiff";

enum Error {
  NO_DIFF = "No diff found",
  SOMETHING_WENT_WRONG = "Something went wrong",
}

const Commit = () => {
  const [changes, setChanges] = useState<
    {
      path: string;
      type: string;
      originalCode?: string;
      newCode: string;
    }[]
  >([]);
  const { hash, id } = useParams<{ hash: string; id: string }>();
  const [error, setError] = useState<Error>();

  const generateChanges = useCallback(
    async ({ hash, id }: { hash: string; id: string }) => {
      try {
        const head = await git.readCommit({
          fs,
          dir: `/${id}`,
          oid: hash,
        });

        if (!head) {
          return;
        }

        const parentSHA = head.commit.parent[0];

        let pastHead = null;

        if (parentSHA) {
          pastHead = await git.readCommit({
            fs,
            dir: `/${id}`,
            oid: parentSHA,
          });
        }

        const changes = await getGitDiff({
          id,
          oldHash: pastHead?.oid,
          newHash: head.oid,
        });

        if (changes.length === 0) {
          setError(Error.NO_DIFF);
          return;
        }

        setChanges(changes);
      } catch (e) {
        console.error(e);

        setError(Error.SOMETHING_WENT_WRONG);
      }
    },
    [id, hash]
  );

  useEffect(() => {
    if (!hash) return;
    if (!id) return;

    generateChanges({ hash, id });
  }, [generateChanges]);

  return (
    <>
      {error ? <div>{error}</div> : null}

      {changes ? (
        <div>
          <div className="flex flex-col gap-4">
            {changes.map(({ path, originalCode, newCode }) => (
              <Disclosure defaultOpen key={path}>
                {({ open }) => (
                  <div className="flex flex-col gap-4">
                    <Disclosure.Button className="text-md flex w-full justify-between rounded-lg px-2 py-4 text-left font-medium transition-colors  hover:bg-neutral-800 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                      <span>{path}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-neutral-400`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="overflow-auto rounded-md">
                      <ReactDiffViewer
                        key={path}
                        oldValue={originalCode || ""}
                        newValue={newCode}
                        splitView={false}
                        useDarkTheme
                        compareMethod={DiffMethod.WORDS_WITH_SPACE}
                        leftTitle={path}
                      />
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default Commit;
