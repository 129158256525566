import {
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  //ScaleIcon,
  FolderIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { u8aToHex } from "@polkadot/util";
import { decodeAddress } from "@polkadot/util-crypto";
import { CID } from "multiformats/cid";
import { decode } from "multiformats/hashes/digest";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "urql";
import shallow from "zustand/shallow";

import Head from "../components/Head";
import LoadingSpinner from "../components/LoadingSpinner";
import useApi from "../hooks/useApi";
import useAccount from "../stores/account";
import useModal, { ModalName } from "../stores/modals";

const IpsByAccountIdQuery = `
  query IpsByAccountIdQuery($accountId: String!) {
    ipsAccounts(where: {account: {accountId_eq: $accountId}}) {
      ips {
        id
      }
    }
  }
`;

const getKiltAddress = async (w3n: string): Promise<string> => {
  const kiltWsProvider = new WsProvider("wss://spiritnet.kilt.io");

  const kiltApi = await ApiPromise.create({ provider: kiltWsProvider });

  const { owner } = (await kiltApi.query.web3Names.owner(w3n))?.toHuman() as {
    owner: string;
  };

  const connectedAccount = (
    await kiltApi.query.didLookup.connectedAccounts.entries(owner)
  )[0][0].args[1].toHuman() as string;

  if (!connectedAccount) {
    throw new Error("NO_KILT_ADDRESS");
  }

  return connectedAccount;
};

const getDecodedAddress = async (address: string) => {
  try {
    const decodedAddress = decodeAddress(address);

    return decodedAddress;
  } catch (e) {
    try {
      const kiltAddress = await getKiltAddress(address);

      const decodedAddress = decodeAddress(kiltAddress);

      return decodedAddress;
    } catch (e) {
      return null;
    }
  }
};

type UserInfo = {
  display?: string;
  legal?: string;
  web?: string;
  riot?: string;
  email?: string;
  twitter?: string;
  image?: string;
};

const User = () => {
  const { address } = useParams();
  const navigate = useNavigate();
  const [accountId, setAccountId] = useState<string>();
  const [query] = useQuery({
    query: IpsByAccountIdQuery,
    variables: { accountId },
    pause: !accountId,
  });
  const [isLoading, setLoading] = useState({
    ips: false,
    identity: false,
  });
  const [hasData, setHasData] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const api = useApi();
  const [repos, setRepos] = useState<string[]>([]);
  const { selectedAccount } = useAccount(
    (state) => ({ selectedAccount: state.selectedAccount }),
    shallow
  );
  const setOpenModal = useModal((state) => state.setOpenModal);

  useEffect(() => {
    if (!address) return;

    setLoading((prev) => ({ ...prev, identity: true }));

    (async () => {
      const decodedAddress = await getDecodedAddress(address);

      if (!decodedAddress) {
        navigate("/404");

        return;
      }

      const accountId = u8aToHex(decodedAddress);

      const query = await api.query.identity.identityOf(accountId);

      if (!query.toHuman()) {
        setHasData(false);

        setLoading((prev) => ({ ...prev, identity: false }));

        return;
      }

      const { info } = query.toHuman() as {
        info: {
          display:
            | {
                Raw: string;
              }
            | "None";
          legal:
            | {
                Raw: string;
              }
            | "None";
          web:
            | {
                Raw: string;
              }
            | "None";
          riot:
            | {
                Raw: string;
              }
            | "None";
          email:
            | {
                Raw: string;
              }
            | "None";
          twitter:
            | {
                Raw: string;
              }
            | "None";
          image:
            | {
                Sha256: string;
              }
            | "None";
        };
      };

      const userInfo: UserInfo = {};

      if (
        info.display !== "None" ||
        info.legal !== "None" ||
        info.web !== "None" ||
        info.riot !== "None" ||
        info.email !== "None" ||
        info.twitter !== "None" ||
        info.image !== "None"
      ) {
        setHasData(true);
      }

      if (info.display !== "None") {
        userInfo.display = info.display.Raw;
      }

      if (info.legal !== "None") {
        userInfo.legal = info.legal.Raw;
      }

      if (info.web !== "None") {
        userInfo.web = info.web.Raw;
      }

      if (info.riot !== "None") {
        userInfo.riot = info.riot.Raw;
      }

      if (info.email !== "None") {
        userInfo.email = info.email.Raw;
      }

      if (info.twitter !== "None") {
        userInfo.twitter = info.twitter.Raw;
      }

      if (info.image !== "None") {
        userInfo.image = info.image.Sha256;
      }

      setUserInfo(userInfo);

      setAccountId(u8aToHex(decodedAddress));

      setLoading((prev) => ({ ...prev, identity: false }));
    })();
  }, [address, navigate, selectedAccount]);

  useEffect(() => {
    if (query.fetching) return;

    if (!query.data) return;

    setLoading((prev) => ({ ...prev, ips: true }));

    const ipsIds: string[] = query.data.ipsAccounts.map(
      ({ ips }: { ips: { id: string } }) => ips.id
    );

    setRepos(ipsIds);

    setLoading((prev) => ({ ...prev, ips: false }));
    (async () => {
      console.log(repos);
    })();
  }, [query.fetching, accountId]);

  return (
    <>
      <Head
        title={userInfo?.display || "User"}
        description={userInfo?.legal || "User"}
      />

      <div className="mx-auto block max-w-7xl gap-8 px-2 sm:flex sm:px-8 xl:p-0">
        {isLoading.identity || isLoading.ips ? (
          <LoadingSpinner />
        ) : hasData ? (
          <>
            <div className="my-8 w-full sm:m-0 sm:w-72">
              <div className="my-4 flex flex-shrink-0">
                {userInfo?.image ? (
                  <img
                    className="h-48 w-48 rounded-full"
                    src={(() => {
                      const digest = decode(
                        Uint8Array.from(
                          Buffer.from(
                            `1220${userInfo.image?.replace("0x", "")}`,
                            "hex"
                          )
                        )
                      );

                      const cid = CID.create(1, 112, digest);

                      return `https://crustwebsites.net/ipfs/${cid.toString()}`;
                    })()}
                    alt="user"
                  />
                ) : (
                  <div className="flex h-48 w-48 select-none items-center justify-center rounded-full border font-bold">
                    NO IMAGE
                  </div>
                )}
              </div>

              <div className="my-4 flex flex-col gap-1">
                {userInfo?.display ? (
                  <span className="truncate text-lg font-bold">
                    {userInfo.display}
                  </span>
                ) : null}
                {userInfo?.legal ? (
                  <span className="truncate text-xs">{userInfo.legal}</span>
                ) : null}
              </div>

              <div className="my-4 flex flex-col gap-4">
                <span className="flex items-center gap-2 truncate text-sm">
                  <EnvelopeIcon className="h-4 w-4" aria-hidden="true" />

                  {userInfo?.email || "-"}
                </span>

                <span className="flex items-center gap-2 truncate text-sm">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="h-4 w-4"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                  {userInfo?.twitter || "-"}
                </span>

                <span className="flex items-center gap-2 truncate text-sm">
                  <ChatBubbleLeftIcon className="h-4 w-4" aria-hidden="true" />
                  {userInfo?.riot || "-"}
                </span>

                <span className="flex items-center gap-2 truncate text-sm">
                  <GlobeAltIcon className="h-4 w-4" aria-hidden="true" />
                  {userInfo?.web || "-"}
                </span>

                {selectedAccount?.address === address ? (
                  <button
                    className="rounded-md border border-transparent bg-amber-400 py-2 px-4 text-sm font-bold text-neutral-900 shadow-sm hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
                    onClick={() =>
                      setOpenModal({
                        name: ModalName.EDIT_IDENTITY,
                        metadata: userInfo ? { user: userInfo } : undefined,
                      })
                    }
                  >
                    Edit Identity
                  </button>
                ) : null}
              </div>
            </div>
            <div className="w-full">
              <h1 className="text-2xl font-bold">Repositories</h1>

              {repos.length > 0 ? (
                <ul className="my-4 divide-y divide-neutral-600">
                  {repos.map((item) => (
                    <li key={item} className="relative py-4">
                      <Link
                        to={`repo/${item}`}
                        className="flex h-full w-full gap-4"
                      >
                        <span className="absolute inset-0" aria-hidden="true" />
                        <span className="flex w-1/2 shrink-0 items-center gap-2 truncate lg:w-1/4">
                          <FolderIcon className="h-4 w-4" />
                          Repository: {item}
                        </span>
                        <span className="hidden w-1/4 shrink-0 items-center gap-2 truncate text-sm text-neutral-400 lg:flex">
                          {/* <ScaleIcon className="h-4 w-4" />
                        {item.license} */}
                        </span>
                        <span className="hidden w-1/4 truncate text-sm text-neutral-400 lg:flex">
                          {/* info here */}
                        </span>
                        <span className="w-1/2 truncate text-right text-sm text-neutral-400 lg:w-1/4">
                          {/* Updated 8 days ago */}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="my-4">
                  No repos yet, we&apos;re working on it!
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="flex w-full flex-col gap-8">
              <div>
                <h1 className="text-lg font-medium leading-6 text-white">
                  No identity found
                </h1>
                <p className="mt-1 text-sm text-neutral-400">
                  This user didn&apos;t setup any identity yet
                </p>
              </div>

              {selectedAccount?.address === address ? (
                <div>
                  <div className="flex flex-col gap-4">
                    <div>
                      <h2 className="font-bold leading-6 text-white">
                        Oh, it looks like you&apos;re the owner!
                      </h2>

                      <p className="mt-1 text-sm ">
                        Would you like to set it up right now?
                      </p>
                    </div>

                    <div>
                      <button
                        className="rounded-md border border-transparent bg-amber-400 py-2 px-4 text-sm font-bold text-neutral-900 shadow-sm hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
                        onClick={() =>
                          setOpenModal({ name: ModalName.EDIT_IDENTITY })
                        }
                      >
                        Create Identity
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default User;
