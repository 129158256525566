import useModal, { ModalName } from "../stores/modals";
import EditIdentity from "./EditIdentity";
import NewRepo from "./NewRepo";
import SelectAccount from "./SelectAccount";

const Modals = () => {
  const { openModal } = useModal((state) => ({
    openModal: state.openModal,
  }));

  return (
    <>
      <SelectAccount isOpen={openModal === ModalName.SELECT_ACCOUNT} />

      <EditIdentity isOpen={openModal === ModalName.EDIT_IDENTITY} />

      <NewRepo isOpen={openModal === ModalName.NEW_REPO} />
    </>
  );
};

export default Modals;
