import { UserIcon } from "@heroicons/react/24/outline";
import { CommitObject } from "isomorphic-git";
import { useEffect, useState } from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";
import shallow from "zustand/shallow";

import LoadingSpinner from "../../../components/LoadingSpinner";
import useRepo from "../../../stores/repo";
import { getCommits } from "../../../utils/getCommits";
import { timeAgo } from "../../../utils/timeAgo";
import Commit from "./Commit";

const Commits = () => {
  const { selectedBranch } = useRepo(
    (state) => ({
      selectedBranch: state.selectedBranch,
    }),
    shallow
  );
  const [commits, setCommits] = useState<
    { hash: string; commit: CommitObject }[] | null
  >(null);
  const { id } = useParams<{ id: string }>();

  const generateCommitList = async (
    id: string | undefined,
    selectedBranch: string
  ) => {
    const commits = await getCommits(id, selectedBranch);

    setCommits(commits);
  };

  useEffect(() => {
    if (selectedBranch) {
      generateCommitList(id, selectedBranch);
    }
  }, [selectedBranch, id]);

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <>
              {commits ? (
                <table className="w-full table-fixed divide-y divide-neutral-200">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6"
                      >
                        Author
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Message
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Hash
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold"
                      >
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-neutral-500">
                    {commits.map((commit) => (
                      <tr key={commit.hash}>
                        <td className="flex gap-4 truncate py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                          <UserIcon
                            className="hidden h-4 w-4 shrink-0 text-white sm:block"
                            aria-hidden="true"
                          />
                          {commit.commit.author.name}
                        </td>
                        <td className="truncate px-3 py-4 text-sm text-neutral-500">
                          {commit.commit.message}
                        </td>
                        <td className="truncate px-3 py-4 text-sm text-neutral-500 transition-colors hover:text-neutral-200">
                          <Link to={commit.hash}>
                            {commit.hash.slice(0, 7)}
                          </Link>
                        </td>
                        <td className="truncate px-3 py-4 text-sm text-neutral-500">
                          {timeAgo(commit.commit.author.timestamp)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <LoadingSpinner />
              )}
            </>
          }
        />

        <Route path=":hash" element={<Commit />} />
      </Routes>
    </>
  );
};

export default Commits;
