import "./index.css";

import { Buffer } from "buffer";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { createClient, Provider as URQLProvider } from "urql";

import Modals from "./modals";
import ApiProvider from "./providers/api";

const client = createClient({
  url: "https://squid.subsquid.io/gitarch-squid/v/101/graphql",
});

window.Buffer = window.Buffer || Buffer;

import Layout from "./components/Layout";
import Home from "./pages/home";
import NotFound from "./pages/not-found";
import Repo from "./pages/repo";
import User from "./pages/user";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ApiProvider>
      <URQLProvider value={client}>
        <HelmetProvider>
          <>
            <Toaster position="bottom-right" />
            <BrowserRouter>
              <Modals />
              <Routes>
                <Route index element={<Home />} />

                <Route path="/" element={<Layout />}>
                  <Route path="repo/:id/*" element={<Repo />} />

                  <Route path="user/:address" element={<User />} />

                  <Route path="404" element={<NotFound />} />

                  <Route
                    path="*"
                    element={<Navigate to="/404" replace={true} />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </>
        </HelmetProvider>
      </URQLProvider>
    </ApiProvider>
  </React.StrictMode>
);
