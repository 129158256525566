import create from "zustand";

import { RepoData } from "../utils/codec";

type RepoMetadata = {
  repoName: string;
  description: string;
  [key: string]: string;
};

type RepoState = {
  repoData: RepoData | null;
  repoDataId: number | null;
  setRepoData: (repoData: RepoData) => void;
  setRepoDataId: (repoDataId: number) => void;
  repoMetadata: RepoMetadata | null;
  setRepoMetadata: (repoMetadata: RepoMetadata) => void;
  selectedBranch: string | null;
  setSelectedBranch: (selectedBranch: string) => void;
  branches: string[];
  setBranches: (branches: string[]) => void;
  repoPath: string;
  setRepoPath: (repoPath: string) => void;
  previousPath: string | null;
};

const useRepo = create<RepoState>()((set) => ({
  repoData: null,
  repoDataId: null,
  setRepoData: (repoData: RepoData) => set(() => ({ repoData })),
  setRepoDataId: (repoDataId: number) => set(() => ({ repoDataId })),
  repoMetadata: null,
  setRepoMetadata: (repoMetadata: RepoMetadata) =>
    set(() => ({ repoMetadata })),
  branches: [],
  setBranches: (branches: string[]) => set(() => ({ branches })),
  selectedBranch: null,
  setSelectedBranch: (selectedBranch: string) =>
    set(() => ({ selectedBranch })),
  repoPath: "",
  setRepoPath: (repoPath: string) =>
    set(() => {
      let previousPath = null;

      if (repoPath) {
        previousPath = repoPath.substring(0, repoPath.lastIndexOf("/") + 1);

        if (previousPath.endsWith("/")) {
          previousPath = previousPath.slice(0, -1);
        }
      }

      return { repoPath, previousPath };
    }),
  previousPath: null,
}));

export default useRepo;
