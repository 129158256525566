import {
  web3Accounts,
  web3Enable,
  web3FromAddress,
} from "@polkadot/extension-dapp";
import { CID, create } from "ipfs-http-client";

const signCrustAccount = async (account: string): Promise<string | null> => {
  await web3Enable("GitArch");

  await web3Accounts();

  const injector = await web3FromAddress(account);

  if (!injector?.signer?.signRaw) return null;

  const sig = (
    await injector.signer.signRaw({
      address: account,
      data: account,
      type: "bytes",
    })
  ).signature;

  return Buffer.from(`sub-${account}:${sig}`).toString("base64");
};

const sendToCrust = async (
  authHeader: string,
  data: { metadata: string; data: Uint8Array }[]
): Promise<{ metadata: string; data: Uint8Array }[]> => {
  const ipfs = create({
    url: "https://gw.crustfiles.app/api/v0",
    headers: {
      Authorization: "Basic " + authHeader,
    },
  });

  const cidList: { metadata: string; data: Uint8Array }[] = [];

  const pinPromises = [];

  for (const item of data) {
    const { cid } = await ipfs.add(item.data);

    if (cid) {
      pinPromises.push(pinToCrust({ authHeader, cid }));
      cidList.push({ metadata: item.metadata, data: cid.bytes });
    } else {
      throw new Error("IPFS_ADD_FAILED");
    }
  }

  await Promise.all(pinPromises);

  return cidList;
};

const pinToCrust = async ({
  authHeader,
  cid,
}: {
  authHeader: string;
  cid: CID;
}) => {
  // request options
  const options = {
    method: "POST",
    body: JSON.stringify({
      cid: cid.toV0().toString(),
      name: cid.toV0().toString(),
    }),
    headers: {
      Authorization: "Bearer " + authHeader,
      "Content-Type": "application/json",
    },
  };

  // send POST request
  const { status } = await fetch("https://pin.crustcode.com/psa/pins", options)
    .then((res) => res.json())
    .then((res) => res as { status: string });

  return status;
};

const sendToCrustGetCid = async (
  authHeader: string,
  data: { metadata: string; data: Uint8Array }[]
): Promise<CID[]> => {
  const ipfs = create({
    url: "https://crustwebsites.net/api/v0",
    headers: {
      Authorization: "Basic " + authHeader,
    },
  });

  const cidList: CID[] = [];

  const pinPromises = [];

  for (const item of data) {
    const { cid } = await ipfs.add(item.data);

    if (cid) {
      pinPromises.push(pinToCrust({ authHeader, cid }));
      cidList.push(cid);
    } else {
      throw new Error("IPFS_ADD_FAILED");
    }
  }

  await Promise.all(pinPromises);

  return cidList;
};

export { sendToCrust, sendToCrustGetCid, signCrustAccount };
