const generateRepoPath = (route: string | undefined) => {
  const rawRepoPath = route?.startsWith("tree")
    ? route.replace("tree", "")
    : route;

  let repoPath = rawRepoPath || "";

  if (rawRepoPath?.startsWith("/")) {
    repoPath = rawRepoPath.slice(0, 0) + rawRepoPath.slice(1);
  }

  if (rawRepoPath?.endsWith("/")) {
    repoPath = rawRepoPath.slice(0, -1);
  }

  return repoPath;
};

export default generateRepoPath;
