import { Dialog, Tab } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  web3Accounts,
  web3Enable,
  web3FromAddress,
} from "@polkadot/extension-dapp";
import { ISubmittableResult } from "@polkadot/types/types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import useAccount from "../stores/account";
import useModal from "../stores/modals";
import useRPC from "../stores/rpc";
import classNames from "../utils/classNames";
import licenses from "../utils/licenses";

enum FormType {
  SIMPLE,
  ADVANCED,
}

type SimpleFormValues = {
  repoName: string;
  description: string;
  license: string;
};

type AdvancedFormValues = SimpleFormValues & {
  executionThreshold: number;
  defaultAssetWeight: number;
  defaultPermission: "YES" | "NO";
};

const NewRepo = ({ isOpen }: { isOpen: boolean }) => {
  const { setOpenModal, metadata } = useModal((state) => ({
    setOpenModal: state.setOpenModal,
    metadata: state.metadata,
  }));
  const { createApi } = useRPC();
  const { selectedAccount } = useAccount(
    (state) => ({ selectedAccount: state.selectedAccount }),
    shallow
  );
  const simpleForm = useForm<SimpleFormValues>();
  const advancedForm = useForm<AdvancedFormValues>();
  const navigate = useNavigate();

  const getSignAndSendCallback = () => {
    let hasFinished = false;

    return ({ events, status }: ISubmittableResult) => {
      // prevents error to be displayed multiple times
      if (hasFinished) return;

      if (status.isInvalid) {
        toast.error("Transaction is invalid");

        hasFinished = true;
      } else if (status.isReady) {
        toast.loading("Creating repository...");
      } else if (status.isDropped) {
        toast.error("Transaction dropped");

        hasFinished = true;
      } else if (status.isInBlock || status.isFinalized) {
        const success = events.find(
          ({ event }) => event.method === "IPSCreated"
        );

        const failed = events.find(
          ({ event }) => event.method === "ExtrinsicFailed"
        );

        if (success) {
          toast.dismiss();

          toast.success("Repository created!");

          hasFinished = true;

          const event = success.event.toHuman();

          // eslint-disable-next-line
          // @ts-ignore
          const ipsId = event?.data?.ipsId;

          setOpenModal({ name: null });

          navigate(`/repo/${ipsId}/setup`);
        } else if (failed) {
          toast.error("Transaction failed");

          hasFinished = true;

          console.error(failed.toHuman(true));
        } else throw new Error("UNKNOWN_RESULT");
      }
    };
  };

  const handleSimpleRepoCreation = simpleForm.handleSubmit(
    async ({ repoName, description, license }) => {
      if (!selectedAccount) {
        toast.error("No connected account");

        return;
      }

      const api = await createApi();

      const rawIpsMetadata = {
        repoName,
        description,
      };

      const ipsMetadata = JSON.stringify(rawIpsMetadata);

      await web3Enable("GitArch");

      await web3Accounts();

      const injector = await web3FromAddress(selectedAccount.address);

      if (metadata?.fork) {
        const ips = (
          await api.query.inv4.ipStorage(parseInt(metadata.fork.id))
        ).toPrimitive() as { metadata: string; data: { ipfId: number }[] };

        if (!ips) {
          throw new Error("QUERY_NOT_FOUND");
        }

        if (!selectedAccount) return;

        const txs = [];

        for (const ipf of ips.data) {
          const { metadata, data } = (
            await api.query.ipf.ipfStorage(ipf.ipfId)
          ).toPrimitive() as {
            metadata: string;
            data: string;
          };

          txs.push(api.tx.ipf.mint(metadata, data));
        }

        let finished = false;

        await api.tx.utility
          .batchAll(txs)
          .signAndSend(
            selectedAccount.address,
            { signer: injector.signer },
            async ({ events, status }: ISubmittableResult) => {
              if (finished) return;
              if (status.isInvalid) {
                toast.error("Transaction is invalid");
              } else if (status.isReady) {
                toast.loading("Minting NFTs...");
              } else if (status.isDropped) {
                toast.error("Transaction dropped");
              } else if (status.isInBlock || status.isFinalized) {
                toast.dismiss();

                const failed = events.find(
                  ({ event }) => event.method === "ExtrinsicFailed"
                );

                const newNFTs = events
                  .filter(({ event }) => event.method === "Minted")
                  .map(
                    ({ event }) =>
                      (event.toPrimitive() as { data: string[] }).data[1]
                  );

                if (newNFTs.length == 2) {
                  toast.success("NFTs minted!");

                  const newNftIds = newNFTs.map((id) => ({
                    ipfId: parseInt(id),
                  }));

                  await api.tx.inv4
                    .createIps(
                      ipsMetadata,
                      newNftIds,
                      false,
                      api.createType("Licenses", license),
                      api.createType("OneOrPercent", { Percent: 1 }),
                      api.createType("OneOrPercent", { Percent: 0 }),
                      false
                    )
                    .signAndSend(
                      selectedAccount.address,
                      { signer: injector.signer },
                      getSignAndSendCallback()
                    );

                  finished = true;
                  return;
                } else if (failed) {
                  toast.error("Merge failed.");

                  console.error(failed.toHuman(true));
                } else throw new Error("UNKNOWN_RESULT");
              }
            }
          );

        throw new Error("SOMETHING_WENT_WRONG");
      }

      await api.tx.inv4
        .createIps(
          ipsMetadata,
          [],
          false,
          api.createType("Licenses", license),
          api.createType("OneOrPercent", { Percent: 1 }),
          api.createType("OneOrPercent", { Percent: 0 }),
          false
        )
        .signAndSend(
          selectedAccount.address,
          { signer: injector.signer },
          getSignAndSendCallback()
        );
    }
  );

  const handleAdvancedFormCreation = advancedForm.handleSubmit(
    async ({
      repoName,
      description,
      license,
      executionThreshold = 1,
      defaultAssetWeight = 0,
      defaultPermission = false,
    }) => {
      if (!selectedAccount) {
        toast.error("No connected account");

        return;
      }

      const api = await createApi();

      const rawIpsMetadata = {
        repoName,
        description,
      };

      const ipsMetadata = JSON.stringify(rawIpsMetadata);

      await web3Enable("GitArch");

      await web3Accounts();

      const injector = await web3FromAddress(selectedAccount.address);

      if (metadata?.fork) {
        const ips = (
          await api.query.inv4.ipStorage(parseInt(metadata.fork.id))
        ).toPrimitive() as { metadata: string; data: { ipfId: number }[] };

        if (!ips) {
          throw new Error("QUERY_NOT_FOUND");
        }

        if (!selectedAccount) return;

        const txs = [];

        for (const ipf of ips.data) {
          const { metadata, data } = (
            await api.query.ipf.ipfStorage(ipf.ipfId)
          ).toPrimitive() as {
            metadata: string;
            data: string;
          };

          txs.push(api.tx.ipf.mint(metadata, data));
        }

        let finished = false;

        await api.tx.utility
          .batchAll(txs)
          .signAndSend(
            selectedAccount.address,
            { signer: injector.signer },
            async ({ events, status }: ISubmittableResult) => {
              if (finished) return;
              if (status.isInvalid) {
                toast.error("Transaction is invalid");
              } else if (status.isReady) {
                toast.loading("Minting NFTs...");
              } else if (status.isDropped) {
                toast.error("Transaction dropped");
              } else if (status.isInBlock || status.isFinalized) {
                toast.dismiss();

                const failed = events.find(
                  ({ event }) => event.method === "ExtrinsicFailed"
                );

                const newNFTs = events
                  .filter(({ event }) => event.method === "Minted")
                  .map(
                    ({ event }) =>
                      (event.toPrimitive() as { data: string[] }).data[1]
                  );

                if (newNFTs.length == 2) {
                  toast.success("NFTs minted!");

                  const newNftIds = newNFTs.map((id) => ({
                    ipfId: parseInt(id),
                  }));

                  await api.tx.inv4
                    .createIps(
                      ipsMetadata,
                      newNftIds,
                      false,
                      api.createType("Licenses", license),
                      api.createType("OneOrPercent", {
                        Percent: executionThreshold,
                      }),
                      api.createType("OneOrPercent", {
                        Percent: defaultAssetWeight,
                      }),
                      defaultPermission
                    )
                    .signAndSend(
                      selectedAccount.address,
                      { signer: injector.signer },
                      getSignAndSendCallback()
                    );

                  finished = true;
                  return;
                } else if (failed) {
                  toast.error("Merge failed.");

                  console.error(failed.toHuman(true));
                } else throw new Error("UNKNOWN_RESULT");
              }
            }
          );

        throw new Error("SOMETHING_WENT_WRONG");
      }

      await api.tx.inv4
        .createIps(
          ipsMetadata,
          [],
          false,
          api.createType("Licenses", license),
          api.createType("OneOrPercent", { Percent: executionThreshold }),
          api.createType("OneOrPercent", { Percent: defaultAssetWeight }),
          defaultPermission
        )
        .signAndSend(
          selectedAccount.address,
          { signer: injector.signer },
          getSignAndSendCallback()
        );
    }
  );

  useEffect(() => {
    simpleForm.reset({
      repoName: metadata?.fork?.name,
      description: metadata?.fork?.description,
    });

    advancedForm.reset({
      repoName: metadata?.fork?.name,
      description: metadata?.fork?.description,
    });
  }, [metadata]);

  return (
    <Dialog open={isOpen} onClose={() => setOpenModal({ name: null })}>
      <Dialog.Overlay className="fixed inset-0 z-40 h-screen w-full bg-black/40 backdrop-blur-md" />

      <button className="pointer fixed top-0 right-0 z-50 flex cursor-pointer flex-col items-center justify-center bg-transparent bg-opacity-50 p-6 text-gray-100 outline-none duration-500 hover:bg-opacity-100 hover:opacity-30">
        <XMarkIcon className="h-5 w-5" />
        <span className="block">close</span>
      </button>

      <Dialog.Panel>
        <div className="fixed left-1/2 top-1/2 z-50 mx-auto block max-h-[calc(100%-2rem)] w-[calc(100%-2rem)] max-w-xl -translate-x-1/2 -translate-y-1/2 transform flex-col overflow-auto rounded-md border border-gray-50 bg-neutral-900 p-6 sm:w-full">
          <div className="flex flex-col gap-4">
            {metadata?.fork ? (
              <div>
                <h1 className="text-lg font-medium leading-6 text-white">
                  Fork Repository
                </h1>
                <p className="mt-1 text-sm text-white">
                  Use this form to fork the repository.
                </p>
              </div>
            ) : (
              <div>
                <h1 className="text-lg font-medium leading-6 text-white">
                  Create a new Repository
                </h1>
                <p className="mt-1 text-sm text-white">
                  Use this form to create the initial repository.
                </p>
              </div>
            )}

            <Tab.Group>
              <Tab.List className="flex gap-6 space-x-1 rounded-md bg-neutral-900/20">
                <Tab
                  key={FormType.SIMPLE}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-md py-2.5 text-sm font-medium leading-5 text-neutral-700",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "bg-neutral-800 text-neutral-100 transition-colors hover:bg-white/[0.12] hover:text-white"
                    )
                  }
                >
                  Simple
                </Tab>
                <Tab
                  key={FormType.ADVANCED}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-md py-2.5 text-sm font-medium leading-5 text-neutral-700",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white shadow"
                        : "bg-neutral-800 text-neutral-100 transition-colors hover:bg-white/[0.12] hover:text-white"
                    )
                  }
                >
                  Advanced
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel
                  key={FormType.SIMPLE}
                  className={classNames(
                    "rounded-md",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral-400 focus:outline-none focus:ring-2"
                  )}
                >
                  <form onSubmit={handleSimpleRepoCreation}>
                    <div className="mt-2 grid grid-cols-6 gap-6 rounded-md">
                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="repoName"
                          className="block text-sm font-medium text-neutral-400"
                        >
                          Repository Name
                        </label>
                        <input
                          type="text"
                          {...simpleForm.register("repoName", {
                            required: true,
                          })}
                          placeholder="Repository Name"
                          className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="license"
                          className="block text-sm font-medium text-neutral-400"
                        >
                          License
                        </label>
                        <select
                          {...simpleForm.register("license", {
                            required: true,
                          })}
                          className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                        >
                          {licenses.map((license) => (
                            <option key={license} value={license}>
                              {license}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-neutral-400"
                        >
                          Description
                        </label>
                        <input
                          type="text"
                          {...simpleForm.register("description", {
                            required: true,
                          })}
                          placeholder="Description"
                          className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-amber-400 py-2 px-4 text-sm font-bold text-neutral-900 shadow-sm transition-colors hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
                        >
                          {metadata?.fork ? "Fork" : "Create"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab.Panel>
                <Tab.Panel
                  key={FormType.ADVANCED}
                  className={classNames(
                    "rounded-md",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral-400 focus:outline-none focus:ring-2"
                  )}
                >
                  <form onSubmit={handleAdvancedFormCreation}>
                    <div className="mt-2 grid grid-cols-6 gap-6 rounded-md">
                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="repoName"
                          className="block text-sm font-medium text-neutral-400"
                        >
                          Repository Name
                        </label>
                        <input
                          type="text"
                          {...advancedForm.register("repoName", {
                            required: true,
                          })}
                          placeholder="Repository Name"
                          className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="license"
                          className="block text-sm font-medium text-neutral-400"
                        >
                          License
                        </label>
                        <select
                          {...advancedForm.register("license", {
                            required: true,
                          })}
                          className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                        >
                          {licenses.map((license) => (
                            <option key={license} value={license}>
                              {license}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-neutral-400"
                        >
                          Description
                        </label>
                        <input
                          type="text"
                          {...advancedForm.register("description", {
                            required: true,
                          })}
                          placeholder="Description"
                          className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 lg:col-span-2">
                        <label
                          htmlFor="executionThreshold"
                          className="block text-sm font-medium text-neutral-400"
                        >
                          Execution Threshold
                        </label>
                        <div className="mt-1 flex w-full rounded-md text-white shadow-sm">
                          <input
                            type="number"
                            {...advancedForm.register("executionThreshold", {
                              required: true,
                              valueAsNumber: true,
                              min: 0,
                              max: 100,
                              value: 50,
                            })}
                            className="block w-full rounded-l-md border border-neutral-300 bg-neutral-900 py-2 px-3 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                          />
                          <span className="inline-flex items-center rounded-r-md border border-l-0 border-neutral-300 bg-neutral-800 px-3 text-sm text-neutral-500">
                            %
                          </span>
                        </div>
                      </div>

                      <div className="col-span-6 lg:col-span-2">
                        <label
                          htmlFor="defaultAssetWeight"
                          className="block text-sm font-medium text-neutral-400"
                        >
                          Default Asset Weight
                        </label>
                        <div className="mt-1 flex w-full rounded-md text-white shadow-sm">
                          <input
                            type="number"
                            {...advancedForm.register("defaultAssetWeight", {
                              required: true,
                              valueAsNumber: true,
                              min: 0,
                              max: 100,
                              value: 1,
                            })}
                            className="block w-full rounded-l-md border border-neutral-300 bg-neutral-900 py-2 px-3 shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                          />
                          <span className="inline-flex items-center rounded-r-md border border-l-0 border-neutral-300 bg-neutral-800 px-3 text-sm text-neutral-500">
                            %
                          </span>
                        </div>
                      </div>

                      <div className="col-span-6 lg:col-span-2">
                        <label
                          htmlFor="defaultPermission"
                          className="block text-sm font-medium text-neutral-400"
                        >
                          Default Permission
                        </label>
                        <select
                          {...advancedForm.register("defaultPermission", {
                            value: "NO",
                          })}
                          className="mt-1 block w-full rounded-md border border-neutral-300 bg-neutral-900 py-2 px-3 text-white shadow-sm focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                        >
                          <option value="NO">No</option>
                          <option value="YES">Yes</option>
                        </select>
                      </div>

                      <div className="col-span-6">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-amber-400 py-2 px-4 text-sm font-bold text-neutral-900 shadow-sm transition-colors hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
                        >
                          {metadata?.fork ? "Fork" : "Create"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default NewRepo;
