import { Listbox, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {} from "isomorphic-git";
import git, {
  CommitObject,
  ParsedBlobObject,
  ParsedCommitObject,
  ParsedTagObject,
  ParsedTreeObject,
  RawObject,
} from "isomorphic-git";
import { Fragment, useEffect, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { useParams } from "react-router-dom";
import shallow from "zustand/shallow";

import useApi from "../../../hooks/useApi";
import useAccount from "../../../stores/account";
import useRepo from "../../../stores/repo";
import buildMultiObject from "../../../utils/buildMultiObject";
import { $MultiObject, $RepoData } from "../../../utils/codec";
import { GitObject } from "../../../utils/codec";
import { createMergeMultisig } from "../../../utils/createMergeMultisig";
import { sendToCrust } from "../../../utils/crust";
import fs from "../../../utils/fs";
import { getCommitsBetween } from "../../../utils/getCommits";
import { getGitDiff } from "../../../utils/getGitDiff";
import { timeAgo } from "../../../utils/timeAgo";

const NewMultisig = () => {
  const { id } = useParams();
  const [branchList, setBranchList] = useState<string[]>([]);
  const [leftBranch, setLeftBranch] = useState<string | null>(null);
  const [rightBranch, setRightBranch] = useState<string | null>(null);
  const [changes, setChanges] = useState<
    | {
        path: string;
        type: string;
        originalCode?: string;
        newCode: string;
      }[]
    | null
  >(null);
  const [commits, setCommits] = useState<
    { hash: string; commit: CommitObject }[] | null
  >(null);
  const [newHead, setNewHead] = useState<string | null>(null);
  const { repoData, repoDataId } = useRepo(
    (state) => ({ repoData: state.repoData, repoDataId: state.repoDataId }),
    shallow
  );

  const api = useApi();
  const { selectedAccount, crustSignatures } = useAccount(
    (state) => ({
      selectedAccount: state.selectedAccount,
      crustSignatures: state.crustSignatures,
    }),
    shallow
  );
  const cache = {};

  const tryMerge = async (): Promise<{
    oid?: string;
    alreadyMerged?: boolean;
    fastForward?: boolean;
    mergeCommit?: boolean;
    tree?: string;
  } | null> => {
    if (!leftBranch || !rightBranch || !selectedAccount) return null;

    const result = await git.merge({
      fs,
      dir: `/${id}`,
      ours: leftBranch,
      theirs: rightBranch,
      noUpdateBranch: true,
      fastForward: false,
      cache,
      author: { name: selectedAccount.meta.name },
    });

    return result;
  };

  const load = async () => {
    if (!leftBranch || !rightBranch) return;

    const result = await tryMerge();

    if (!result?.oid) return;

    const targetRef = await git.resolveRef({
      fs,
      dir: `/${id}`,
      ref: leftBranch,
    });

    const newHeadRef = await git.resolveRef({
      fs,
      dir: `/${id}`,
      ref: rightBranch,
    });

    const commitList = await getCommitsBetween(id, targetRef, newHeadRef, []);

    setCommits(commitList);

    setNewHead(result.oid);

    const changes = await getGitDiff({
      id,
      oldHash: targetRef,
      newHash: result.oid,
    });

    setChanges(changes);
  };

  const getNewObjects = async (
    head: string,
    repoDataObjects: string[]
  ): Promise<Map<string, GitObject>> => {
    const newObjects: Map<string, GitObject> = new Map();

    const firstObj = (await git.readObject({
      fs,
      dir: `/${id}`,
      format: "parsed",
      oid: head,
    })) as
      | ParsedBlobObject
      | ParsedCommitObject
      | ParsedTagObject
      | ParsedTreeObject;

    const stack = [firstObj];

    while (stack.length > 0) {
      const currentObject = stack.pop();
      if (!currentObject) break;

      if (repoDataObjects.includes(currentObject.oid)) continue;
      if (newObjects.has(currentObject.oid)) continue;

      const data = (
        (await git.readObject({
          fs,
          dir: `/${id}`,
          format: "content",
          oid: currentObject.oid,
        })) as RawObject
      ).object;

      switch (currentObject.type) {
        case "commit":
          {
            const obj = {
              git_hash: currentObject.oid,
              data,
              metadata: {
                _tag: "Commit",
                parent_git_hashes: new Set(currentObject.object.parent),
                tree_git_hash: currentObject.object.tree,
              } as {
                _tag: "Commit";
                parent_git_hashes: Set<string>;
                tree_git_hash: string;
              },
            };

            newObjects.set(currentObject.oid, obj);

            const tree = (await git.readObject({
              fs,
              dir: `/${id}`,
              format: "parsed",
              oid: currentObject.object.tree,
            })) as ParsedTreeObject;

            stack.push(tree);

            for (const parent of currentObject.object.parent) {
              const object = (await git.readObject({
                fs,
                dir: `/${id}`,
                format: "parsed",
                oid: parent,
              })) as
                | ParsedBlobObject
                | ParsedCommitObject
                | ParsedTagObject
                | ParsedTreeObject;

              stack.push(object);
            }
          }
          break;

        case "tree":
          {
            const obj = {
              git_hash: currentObject.oid,
              data,
              metadata: {
                _tag: "Tree",
                entry_git_hashes: new Set(
                  currentObject.object.map((entry) => entry.oid)
                ),
              } as {
                _tag: "Tree";
                entry_git_hashes: Set<string>;
              },
            };

            newObjects.set(currentObject.oid, obj);

            for (const entry of currentObject.object) {
              // Skipping submodules for now
              if (entry.type === "commit") continue;

              const object = (await git.readObject({
                fs,
                dir: `/${id}`,
                format: "parsed",
                oid: entry.oid,
              })) as
                | ParsedBlobObject
                | ParsedCommitObject
                | ParsedTagObject
                | ParsedTreeObject;

              stack.push(object);
            }
          }
          break;

        case "tag":
          {
            const obj = {
              git_hash: currentObject.oid,
              data,
              metadata: {
                _tag: "Tag",
                target_git_hash: currentObject.object.object,
              } as {
                _tag: "Tag";
                target_git_hash: string;
              },
            };

            newObjects.set(currentObject.oid, obj);

            const object = (await git.readObject({
              fs,
              dir: `/${id}`,
              format: "parsed",
              oid: currentObject.object.object,
            })) as
              | ParsedBlobObject
              | ParsedCommitObject
              | ParsedTagObject
              | ParsedTreeObject;

            stack.push(object);
          }
          break;

        case "blob":
          {
            const obj = {
              git_hash: currentObject.oid,
              data,
              metadata: {
                _tag: "Blob",
              } as {
                _tag: "Blob";
              },
            };

            newObjects.set(currentObject.oid, obj);
          }

          break;
      }
    }

    return newObjects;
  };

  const createMerge = async () => {
    if (
      !leftBranch ||
      !rightBranch ||
      !newHead ||
      !repoData ||
      !selectedAccount ||
      !id ||
      !repoDataId
    )
      return;

    if (!crustSignatures[selectedAccount.address]) return;

    const expandedTargetRef = await git.expandRef({
      fs,
      dir: `/${id}`,
      ref: leftBranch,
    });

    const newRepoData = structuredClone(repoData);
    newRepoData.refs.set(expandedTargetRef, newHead);

    const objectList = await getNewObjects(
      newHead,
      new Array(...repoData.objects.keys())
    );

    const newMultiObject = await buildMultiObject(objectList);

    for (const objHash of objectList.keys()) {
      newRepoData.objects.set(objHash, newMultiObject.hash);
    }

    const cidList = await sendToCrust(
      crustSignatures[selectedAccount.address],
      [
        {
          metadata: newMultiObject.hash,
          data: $MultiObject.encode(newMultiObject),
        },
        { metadata: "RepoData", data: $RepoData.encode(newRepoData) },
      ]
    );

    await createMergeMultisig(
      api,
      selectedAccount.address,
      parseInt(id),
      rightBranch,
      leftBranch,
      newHead,
      cidList,
      repoDataId
    );
  };

  useEffect(() => {
    (async () => {
      await load();
    })();
  }, [leftBranch, rightBranch]);

  useEffect(() => {
    (async () => {
      const branches = await git.listBranches({ fs, dir: `/${id}` });
      setBranchList(branches);
      setLeftBranch(branches[0]);
      setRightBranch(branches[branches.length - 1]);
    })();
  }, []);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center gap-4 rounded-md border px-4 py-2">
        <PencilSquareIcon
          className="h-5 w-5 text-neutral-200"
          aria-hidden="true"
        />

        <Listbox value={leftBranch} onChange={setLeftBranch}>
          <div className="relative z-10">
            <Listbox.Button className="inline-flex w-full justify-center rounded-md border bg-opacity-20 px-2 py-1 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <span className="pr-2">base:</span>{" "}
              <span className="font-bold">{leftBranch}</span>
              <ChevronDownIcon
                className="ml-2 -mr-1 h-5 w-5 text-neutral-200 hover:text-neutral-100"
                aria-hidden="true"
              />
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md border bg-neutral-900 py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {branchList.map((branch) => (
                  <Listbox.Option
                    key={branch}
                    className={({ active }) =>
                      `relative cursor-pointer select-none rounded-md py-2 pl-10 pr-4 ${
                        active ? "bg-neutral-100 text-neutral-900" : null
                      }`
                    }
                    value={branch}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {branch}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-600">
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>

        <ArrowLeftIcon
          className="h-5 w-5 cursor-pointer text-neutral-50"
          onClick={() => {
            setLeftBranch(rightBranch);
            setRightBranch(leftBranch);
          }}
        />

        <Listbox value={rightBranch} onChange={setRightBranch}>
          <div className="relative z-10">
            <Listbox.Button className="inline-flex w-full justify-center rounded-md border bg-opacity-20 px-2 py-1 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <span className="pr-2">compare:</span>{" "}
              <span className="font-bold">{rightBranch}</span>
              <ChevronDownIcon
                className="ml-2 -mr-1 h-5 w-5 text-neutral-200 hover:text-neutral-100"
                aria-hidden="true"
              />
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md border bg-neutral-900 py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {branchList.map((branch) => (
                  <Listbox.Option
                    key={branch}
                    className={({ active }) =>
                      `relative cursor-pointer select-none rounded-md py-2 pl-10 pr-4 ${
                        active ? "bg-neutral-100 text-neutral-900" : null
                      }`
                    }
                    value={branch}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {branch}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-neutral-600">
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>

        <button
          className="inline-flex rounded-md border bg-opacity-20 px-2 py-1 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          onClick={createMerge}
        >
          Create Merge
        </button>
        <span>Not ready yet, this WILL wreck your repo!</span>
      </div>

      {commits ? (
        <div>
          <ul className="divide-y divide-gray-500">
            {commits.map((commit) => (
              <li key={commit.hash} className="py-4">
                <div className="flex space-x-3">
                  <UserIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  <div className="flex-1 space-y-1">
                    <div className="flex items-center justify-between">
                      <div className="flex">
                        <h3 className="text-sm font-medium">
                          {commit.commit.author.name}
                        </h3>

                        <p className="text-sm text-gray-500">
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {commit.commit.message}
                        </p>
                      </div>
                      <p className="text-sm text-gray-500">
                        {timeAgo(commit.commit.author.timestamp)}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {changes ? (
        <div>
          <div className="flex flex-col gap-8 rounded-md">
            {changes.map(({ path, originalCode, newCode }) => (
              <ReactDiffViewer
                key={path}
                oldValue={originalCode || ""}
                newValue={newCode}
                splitView={false}
                useDarkTheme
                compareMethod={DiffMethod.WORDS_WITH_SPACE}
                leftTitle={path}
              />
            ))}
          </div>
        </div>
      ) : null}

      {!commits || !changes ? (
        <div className="flex items-center justify-center gap-2">
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />{" "}
          <span className="text-md">There isn&apos;t anything to compare.</span>
        </div>
      ) : null}
    </div>
  );
};

export default NewMultisig;
